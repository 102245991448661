<template>
  <div>
    <v-app v-if="this.$vuetify.breakpoint.name=='xl' || this.$vuetify.breakpoint.name=='lg' || this.$vuetify.breakpoint.name=='md'" class="large-screen">
      <div class="navbar-large-screen">
        <v-app-bar class="ps-8 pe-8" height="115">
          <v-row class="d-flex align-center">
            <!-- <router-link to="/"> </router-link> -->
            <v-btn
              color="#1E1E1E"
              class="me-16 btn-navbar text-h6 font-weight-bold"
              v-model="show"
              height="84"
              text
              @click="$emit('handle-show')"
              expand-icon="mdi-menu-down"
            >
              Course
            
              <v-icon class="mt-1 ms-1" color="#F39C12">mdi-chevron-down</v-icon>
            </v-btn>
           

            <v-spacer></v-spacer>
            <v-col lg="4">
              <div align="center">
                <v-img
                  alt="nextgrade Logo"
                  contain
                  src="@/assets/iconsdashboard/nexrgrade-logo-svg.svg"
                  transition="scale-transition"
                  width="80"
                  style="cursor: pointer"
                  @click="
                    $router.push('/').catch((e) => {
                      $emit('handle-show-off');
                    }),
                      $emit('handle-show-off')
                  "
                />
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <div class="float-left" v-if="!logged_in">
              <v-btn
                icon
                depressed
                @click="$emit('handle-search')"
                class="me-4"
              >
                <v-avatar size="auto">
                  <v-img src="@/assets/iconsdashboard/search-icon.svg"></v-img>
                </v-avatar>
              </v-btn>
              <v-btn
                color="#1E1E1E"
                tile
                height="84"
                class="me-3 text-capitalize activee-button text-h6 font-weight-bold button"
                text
                @click="
                  $router.push('/login').catch((e) => {
                    $emit('handle-show-off');
                  }),
                    $emit('handle-show-off')
                "
              >
                Login
              </v-btn>
              <v-btn
                color="#1E1E1E"
                tile
                height="84"
                class="btn-navbar text-h6 font-weight-bold activee-button"
                text
                @click="
                  $router.push('/signup').catch((e) => {
                    $emit('handle-show-off');
                  }, $emit('handle-show-off'))
                "
              >
                Sign up
              </v-btn>
            </div>
            <div class="float-left" v-else>
              <template>
                <v-row align="center" justify="center">
                  <v-col lg="4" align="center">
                    <v-btn icon depressed @click="$emit('handle-search')">
                      <v-avatar size="auto">
                        <v-img
                          src="@/assets/iconsdashboard/search-icon.svg"
                        ></v-img>
                      </v-avatar>
                    </v-btn>
                  </v-col>
                  <v-col
                    lg="4"
                    align="center"
                    v-if="
                      is_student &&
                      $store.state.student.cust_client_id > 1 &&
                      $store.state.student.location_id > 1
                    "
                  >
                    <v-menu
                      min-height="560"
                      min-width="auto"
                      left
                      rounded
                      :close-on-content-click="hideNotifcation"
                      max-width="432"
                      :offset-y="offset"
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          icon
                          depressed
                          @click="$emit('handle-show-off')"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-avatar size="auto" @click="listNotification(), hideNotifcation = false">
                            <v-img
                              src="@/assets/iconsdashboard/notification-icon.svg"
                            ></v-img>
                          </v-avatar>

                          <v-badge
                            :content="messages"
                            :value="messages"
                            color="red"
                            overlap
                            class="mt-n8"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-badge>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card flat width="100%">
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="d-flex justify-space-between"
                                >
                                  <div class="d-flex">
                                    <h3 style="font-size: 18px">
                                      Notification
                                    </h3>
                                    <span width="350" class="mt-n3 ms-2" style="cursor:pointer">
                                      <v-select
                                        v-model="selected_notification_type"
                                        :items="notification_read"
                                        selected="All"
                                        color="#2C3E50" 
                                        style="box-shadow: 0px !important"
                                        solo
                                        flat
                                        @change="listNotification(selected_notification_type)"
                                      >

                                      </v-select>
                                    </span>
                                  </div>
                                  <span 
                                    class="ps-6 mt-1 text-capitalize"
                                    >
                                    <h6
                                      style="
                                        color: #1a1f36;
                                        font-weight: 400;
                                        font-size: 14px;
                                        cursor:pointer
                                      "
                                       @click="markAsReadAll()"
                                      class="ps-10"
                                    >
                                      Mark all as read
                                      <v-icon
                                        small
                                        color="#1A1F36"
                                        class="mt-n1"
                                        >mdi-check-circle-outline</v-icon
                                      >
                                    </h6>
                                  </span>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                        <div class="notification-scroll">
                          <v-card
                            class="pa-5"
                            flat
                            style="cursor: pointer"
                            v-for="notification in notificationsList"
                            :key="notification.id"
                            @click="markAsRead(notification), hideNotifcation = true"
                          >
                            <v-row>
                              <v-col lg="2" sm="2" cols="2">
                                <v-badge
                                  v-if="
                                    notification.notification_user_view != 1
                                  "
                                  color="#F39C12"
                                  dot
                                  style="
                                    width: 5px !important;
                                    height: 5px !important;
                                  "
                                ></v-badge>
                                <v-avatar
                                  class="ms-5 mt-n2"
                                  size="50"
                                  color="grey"
                                >
                                  <v-img v-if="notification.sender_img"
                                    :src="APP_URL+notification.sender_img"
                                  ></v-img>
                                  <span v-else class="white--text text-h5">{{notification.sneder_name[0]}}</span>
                                </v-avatar>
                              </v-col>
                              <v-col lg="10" sm="10" cols="10">
                                <h3 class="mt-0 ps-2">
                                  <!-- {{ notification.notification_title }} -->
                                  <span class="font-weight-regular">{{
                                    notification.notification_body
                                  }} </span>
                                </h3>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="12" cols="12">
                                <h4
                                  style="
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #a5acb8;
                                    padding-left: 20%;
                                  "
                                  class="mt-n2"
                                >
                                  {{ notification.notify_time }}
                                </h4>
                              </v-col>
                            </v-row>
                            <v-divider class="mt-2"></v-divider>
                          </v-card>
                         
                          <v-card
                            class="pa-10"
                            flat
                            style="cursor: pointer"
                            v-if="notificationsList.length == 0"
                          >
                            <h2
                              style="
                                font-size: 18px;
                                line-height: 20px;
                                color: #f39c12;
                                text-align: center;
                              "
                            >
                              No Notifications Yet!
                            </h2>
                            <div class="mt-6" align="center">
                              <v-img
                                width="100"
                                src="@/assets/iconsdashboard/bell.svg"
                              ></v-img>
                            </div>
                          </v-card>
                        </div>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col
                    lg="4"
                    :class="
                      $store.state.student.cust_client_id > 1 ? '' : 'ml-5'
                    "
                    align="center"
                  >
                    <v-menu
                      min-width="253"
                      left
                      bottom
                      rounded
                      :offset-y="offset"
                      class="pa-5"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          @click="$emit('handle-show-off')"
                        >
                          <v-avatar color="#2C3E50" size="40">
                            <img v-if="$store.state.student_img" :src="`${APP_URL}${$store.state.student_img}`" alt="" />
                            <h3 v-else class="white--text">
                              {{ $store.state.student_name[0] }}
                            </h3>
                          </v-avatar>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-list-item-group>
                          <div align="left">
                            <div class="d-flex justify-space-around pa-5">
                              <v-avatar color="#2C3E50" size="36">
                                <img v-if="$store.state.student_img" :src="`${APP_URL}${$store.state.student_img}`" alt="" />
                                <h3 v-else class="white--text">
                                  {{ $store.state.student_name[0] }}
                                </h3>
                              </v-avatar>
                              <h3 class="ps-0 pt-0 ms-n2 mt-2" style="line-height:17px; max-width: 50%;">
                                {{ $store.state.student_name }} <br />
                                <span style="color: #888888; font-size: 12px">
                                  {{ $store.state.student.location_name }} |
                                  {{ $store.state.student.client_name }}
                                </span>
                              </h3>
                            </div>
                            <v-divider class="ms-4 me-5"></v-divider>
                            <v-card flat class="ps-5 pe-5">
                              <v-list>
                                <v-list-item-group>
                                  <v-list-item
                                    @click="
                                     is_student ? $router
                                        .push('/dashboard/myprofile')
                                        .catch((e) => {}) :
                                        $router
                                        .push('/teacher/dashboard/profile')
                                        .catch((e) => {})
                                    "
                                  >
                                    <v-list-item-icon>
                                      <v-img
                                        src="@/assets/iconsdashboard/settings.svg"
                                      >
                                      </v-img>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        style="
                                          font-size: 18px;
                                          text-align: start;
                                        "
                                      >
                                        Settings
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="my-0"></v-divider>
                                  <v-list-item
                                    v-if="
                                      is_student &&
                                      $store.state.student.cust_client_id ==
                                        1 &&
                                      $store.state.student.location_id == 1
                                    "
                                    @click.stop="
                                      showDialog($store.state.student_id)
                                    "
                                  >
                                    <v-list-item-icon>
                                      <v-icon v-text="'mdi-account-remove'">
                                      </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        style="
                                          font-size: 18px;
                                          text-align: start;
                                        "
                                        v-text="'Delete Account'"
                                      >
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="my-0"></v-divider>

                                  <v-list-item @click="logout()">
                                    <v-list-item-icon>
                                      <v-img
                                        src="@/assets/iconsdashboard/log-out.svg"
                                      ></v-img>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        style="
                                          font-size: 18px;
                                          text-align: start;
                                        "
                                        v-text="'Logout'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="my-0"></v-divider>
                                </v-list-item-group>
                              </v-list>
                            </v-card>
                          </div>
                        </v-list-item-group>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>
              </template>
            </div>
          </v-row>
        </v-app-bar>
        <v-divider></v-divider>
      </div>
    </v-app>

    <!-- tablet and moblie screen -->
    <v-app v-if="this.$vuetify.breakpoint.name=='sm' || this.$vuetify.breakpoint.name=='xs'"  class="mobile-screen">
      <div class="navbar-large-screen">
        <!-- <v-app-bar color="white accent-4 ps-16 pe-16" height="60" dense> -->
        <v-sheet
          height="auto"
          class="overflow-hidden"
          style="position: relative"
        >
          <v-navigation-drawer v-model="drawer" fixed temporary>
            <v-card class="pa-2" color="#2C3E50" tile width="300" flat>
              <v-row>
                <v-col lg="12" cols="12" align="right">
                  <v-btn
                    color="#ffffff"
                    icon
                    class="mt-5"
                    @click.stop="drawer = !drawer"
                  >
                    <v-icon medium>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

  <template v-if="this.$store.state.student.cust_client_id >1  && this.$store.state.student.location_id > 1 ">
    <v-list>
      <v-list-item-group v-model="model">
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff;padding: 10px 0 20px;"
        >
          <v-list-item
            @click="
              $router.push('/dashboard/student').catch((e) => {})
            "
            class="mobile-screen ps-lg-10 ps-sm-2 ps-md-1 d-flex"
          >
            <v-list-item-icon>
              <v-icon large color="#ffffff"
                >mdi-view-dashboard-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="text-uppercase"
                style="cursor: pointer"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 700;
                    color: #ffffff;
                  "
                  class="ps-md-0"
                >
                  Dashboard
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff; padding: 30px 0"
        >
          <div class="mt-0 ms-4 d-flex mobile-screen">
            <span>
              <v-img
                src="@/assets/iconsdashboard/course-book-icon-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pt-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              COURSES
            </h5>
          </div>

          <v-list-item
            class="mobile-screen"
            @click="
              $router.push('/dashboard/courses').catch((e) => {})
            "
           
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  School Courses
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/dashboard/courses/archive')
                .catch((e) => {})
            "
           
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Archive Courses
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/dashboard/courses/other')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                 
                  NG  Courses
                     
                 
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff; padding: 30px 0"
         
        >
          <div class="mt-0 ms-4 d-flex">
            <span>
              <v-img
                src="@/assets/iconsdashboard/categoris-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pb-0 ps-lg-9 ps-md-5 ps-5"
              style="color: #f39c12; font-size: 18px"
            >
              Exams
            </h5>
          </div>
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/dashboard/exams/previous')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-md-0 ps-lg-5"
                >
                  Previous Exams
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/dashboard/exams/upcoming')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Upcoming Exams
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/dashboard/exams/available')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Available Exams
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <!-- Student Exams end -->

        <div class="mobile-screen" style="padding: 30px 0">
          <div class="ms-4 d-flex">
            <span>
              <v-img
                src="@/assets/iconsdashboard/user-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pb-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              MY ACCOUNT
            </h5>
          </div>

          <v-list-item
            class="mobile-screen"
            @click="
              $router.push('/dashboard/myprofile').catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Profile
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/dashboard/change/password')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Change Password
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </template>

  <template v-else-if="this.$store.state.student.cust_client_id == 1  && this.$store.state.student.location_id == 1 ">
    <v-list  min-height="600">
      <v-list-item-group v-model="model">
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff;padding: 10px 0 20px;"
        >
          <v-list-item
            @click="
              $router.push('/dashboard/student').catch((e) => {})
            "
            class="mobile-screen ps-lg-10 ps-sm-2 ps-md-1 d-flex"
          >
            <v-list-item-icon>
              <v-icon large color="#ffffff"
                >mdi-view-dashboard-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="text-uppercase"
                style="cursor: pointer"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 700;
                    color: #ffffff;
                  "
                  class="ps-md-0"
                >
                  Dashboard
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff; padding: 30px 0"
        >
          <div class="mt-0 ms-4 d-flex mobile-screen">
            <span>
              <v-img
                src="@/assets/iconsdashboard/course-book-icon-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pt-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              COURSES
            </h5>
          </div>

         
    
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/dashboard/courses/other')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                 
                      Courses
                     
                 
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
       
       
        <div class="mobile-screen" style="padding: 30px 0">
          <div class="ms-4 d-flex">
            <span>
              <v-img
                src="@/assets/iconsdashboard/user-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pb-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              MY ACCOUNT
            </h5>
          </div>

          <v-list-item
            class="mobile-screen"
            @click="
              $router.push('/dashboard/myprofile').catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Profile
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/dashboard/change/password')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Change Password
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </template>

  <template v-else-if="this.$store.state.student.user_client_id && this.$store.state.student.user_loc_id && material_draw" >
    <v-list  class="course-material-navigation">
        <v-list-item-group v-model="model">
          <div
            class="mobile-screen"
            style="border-bottom: 1px solid #ffffff; padding: 20px 0"
          >
            <div class="mt-0 ms-4 d-flex mobile-screen">
              <span>
                <v-img
                  src="@/assets/iconsdashboard/courses-material-white.svg"
                  class="ms-lg-6 ms-0"
                >
                </v-img>
              </span>
              <h5
                class="text-left pt-0 ps-lg-9 ps-md-5 ps-6"
                style="color: #f39c12; font-size: 18px"
              >
                MATERIAL
              </h5>
            </div>
            <v-list-item
              class="mobile-screen"
              @click="
                $router
                  .push(
                    `/course/material/units/${$route.params.course_id}`
                  )
                  .catch((e) => {})
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                  style="cursor: pointer; width: 50%"
                >
                  <h4
                    style="
                      font-size: 18px;
                      font-family: lato-light;
                      font-weight: 400;
                      color: #ffffff;
                    "
                    class="ps-lg-5 ps-md-0"
                  >
                    Units
                  </h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="mobile-screen"
              @click="
                $router
                  .push(
                    `/course/material/lessons/${$route.params.course_id}`
                  )
                  .catch((e) => {})
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                  style="cursor: pointer; width: 50%"
                >
                  <h4
                    style="
                      font-size: 18px;
                      font-family: lato-light;
                      font-weight: 400;
                      color: #ffffff;
                    "
                    class="ps-lg-5 ps-md-0"
                  >
                    Lessons
                  </h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="mobile-screen"
              @click="
                $router
                  .push(
                    `/course/material/lectures/${$route.params.course_id}`
                  )
                  .catch((e) => {})
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                  style="cursor: pointer; width: 50%"
                >
                  <h4
                    style="
                      font-size: 18px;
                      font-family: lato-light;
                      font-weight: 400;
                      color: #ffffff;
                    "
                    class="ps-lg-5 ps-md-0"
                  >
                    Lectures
                  </h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="mobile-screen"
              @click="
                $router
                  .push(
                    '/course/material/units/listing/' +
                      $route.params.course_id
                  )
                  .catch((e) => {})
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                  style="cursor: pointer; width: 50%; color: white"
                >
                  <h4
                    style="
                      font-size: 18px;
                      font-family: lato-light;
                      font-weight: 400;
                    "
                    class="ps-lg-5 ps-md-0"
                  >
                    Material Order
                  </h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="mobile-screen"
              @click="
                $router
                  .push(
                    '/course/material/students/' +
                      $route.params.course_id
                  )
                  .catch((e) => {})
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                  style="cursor: pointer; width: 50%; color: white"
                >
                  <h4
                    style="
                      font-size: 18px;
                      font-family: lato-light;
                      font-weight: 400;
                    "
                    class="ps-lg-5 ps-md-0"
                  >
                    Students
                  </h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
        <v-card flat tile class="mt-16" color="#2C3E50">
                    <v-row
                      @click="$router.push('/teacher/dashboard/courses')"
                      style="cursor: pointer"
                      class="pt-16"
                    >
                      <v-col lg="12" cols="12">
                        <v-row style="background: #2c3e50" class="pt-10">
                          <v-col lg="2" sm="3" cols="3">
                            <v-img
                              class="ms-3"
                              src="@/assets/iconsdashboard/returen-back-whitw.svg"
                            ></v-img>
                          </v-col>
                          <v-col lg="10" sm="9" cols="9">
                            <h4 style="color: #f39c12" class="pt-0">
                              RETURN BACK
                            </h4>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
      </v-list>
  </template>
  <template v-else-if="this.$store.state.student.user_client_id > 1  && this.$store.state.student.user_loc_id > 1 ">
    <v-list>
      <v-list-item-group v-model="model">
        <div
          class="mobile-screen"
          style="
            border-bottom: 1px solid #ffffff;
            padding: 10px 0 20px;
          "
        >
          <v-list-item
            @click="
              $router.push('/teacher/dashboard').catch((e) => {})
            "
            class="mobile-screen ps-lg-10 ps-sm-2 ps-md-1 d-flex"
          >
            <v-list-item-icon>
              <v-icon large color="#ffffff"
                >mdi-view-dashboard-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="text-uppercase"
                style="cursor: pointer"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 700;
                    color: #ffffff;
                  "
                  class="ps-md-0"
                >
                  Dashboard
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff; padding: 30px 0"
        >
          <div class="mt-0 ms-4 d-flex mobile-screen">
            <span>
              <v-img
                src="@/assets/iconsdashboard/course-book-icon-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pt-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              COURSES
            </h5>
          </div>
         
        
            <v-list-item
              class="mobile-screen"
              @click="
                $router
                  .push('/teacher/dashboard/courses')
                  .catch((e) => {})
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                  style="cursor: pointer; width: 50%"
                >
                  <h4
                    style="
                      font-size: 18px;
                      font-family: lato-light;
                      font-weight: 400;
                      color: #ffffff;
                    "
                    class="ps-lg-5 ps-md-0"
                  >
                    School Courses
                  </h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
         
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/courses/public')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  NG Courses
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff; padding: 30px 0"
        >
          <div class="mt-0 ms-4 d-flex">
            <span>
              <v-img
                src="@/assets/iconsdashboard/categoris-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pb-0 ps-lg-9 ps-md-5 ps-5"
              style="color: #f39c12; font-size: 18px"
            >
              QUESTION CATEGORIES
            </h5>
          </div>
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/question/categories')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-md-0 ps-lg-5"
                >
                  Question Categories
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/question')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Questions
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <!-- question category end -->

        <!-- STUDENTS-->
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff; padding: 30px 0"
        >
          <div class="mt-0 ms-4 d-flex">
            <span>
              <v-img
                src="@/assets/iconsdashboard/student-male-white.svg"
                class="ms-lg-6 ms-0"
              ></v-img>
            </span>
            <h5
              class="text-left pb-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              STUDENTS
            </h5>
          </div>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/students')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Students
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/exam')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Exams
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/assigned/exams')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Exams Assignments
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/assigned/quiz')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Quiz Assignment
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/assigned/practice')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-5 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Practice Assignment
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <!-- STUDENTS end -->

        <div class="mobile-screen" style="padding: 30px 0">
          <div class="ms-4 d-flex">
            <span>
              <v-img
                src="@/assets/iconsdashboard/user-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pb-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              MY ACCOUNT
            </h5>
          </div>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/profile')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Profile
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/changepassword')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Change Password
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>

  
  </template>


  <template v-else-if="this.$store.state.student.user_client_id == 1  && this.$store.state.student.user_loc_id == 1 ">
    <v-list>
      <v-list-item-group v-model="model">
        <div
          class="mobile-screen"
          style="
            border-bottom: 1px solid #ffffff;
            padding: 10px 0 20px;
          "
        >
          <v-list-item
            @click="
              $router.push('/teacher/dashboard').catch((e) => {})
            "
            class="mobile-screen ps-lg-10 ps-sm-2 ps-md-1 d-flex"
          >
            <v-list-item-icon>
              <v-icon large color="#ffffff"
                >mdi-view-dashboard-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="text-uppercase"
                style="cursor: pointer"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 700;
                    color: #ffffff;
                  "
                  class="ps-md-0"
                >
                  Dashboard
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff; padding: 30px 0"
        >
          <div class="mt-0 ms-4 d-flex mobile-screen">
            <span>
              <v-img
                src="@/assets/iconsdashboard/course-book-icon-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pt-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              COURSES
            </h5>
          </div>
         

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/courses/public')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                 Courses
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff; padding: 30px 0"
        >
          <div class="mt-0 ms-4 d-flex">
            <span>
              <v-img
                src="@/assets/iconsdashboard/categoris-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pb-0 ps-lg-9 ps-md-5 ps-5"
              style="color: #f39c12; font-size: 18px"
            >
              QUESTION CATEGORIES
            </h5>
          </div>
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/question/categories')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-md-0 ps-lg-5"
                >
                  Question Categories
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/question')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Questions
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <!-- question category end -->

        <!-- STUDENTS-->
        <div
          class="mobile-screen"
          style="border-bottom: 1px solid #ffffff; padding: 30px 0"
        >
          <div class="mt-0 ms-4 d-flex">
            <span>
              <v-img
                src="@/assets/iconsdashboard/student-male-white.svg"
                class="ms-lg-6 ms-0"
              ></v-img>
            </span>
            <h5
              class="text-left pb-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              STUDENTS
            </h5>
          </div>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/students')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Students
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/exam')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Exams
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/assigned/exams')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Exams Assignments
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/assigned/quiz')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Quiz Assignment
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/assigned/practice')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-5 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Practice Assignment
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <!-- STUDENTS end -->

        <div class="mobile-screen" style="padding: 30px 0">
          <div class="ms-4 d-flex">
            <span>
              <v-img
                src="@/assets/iconsdashboard/user-white.svg"
                class="ms-lg-6 ms-0"
              >
              </v-img>
            </span>
            <h5
              class="text-left pb-0 ps-lg-9 ps-md-5 ps-6"
              style="color: #f39c12; font-size: 18px"
            >
              MY ACCOUNT
            </h5>
          </div>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/profile')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Profile
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-screen"
            @click="
              $router
                .push('/teacher/dashboard/changepassword')
                .catch((e) => {})
            "
          >
            <v-list-item-content>
              <v-list-item-title
                class="pt-2 pb-2 ps-lg-16 ps-md-7 ps-12"
                style="cursor: pointer; width: 50%"
              >
                <h4
                  style="
                    font-size: 18px;
                    font-family: lato-light;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  class="ps-lg-5 ps-md-0"
                >
                  Change Password
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>

  
  </template>



              <hr class="mobile-screen" />
            </v-card>
          </v-navigation-drawer>
          <v-container fluid class="fill-height pa-0">
            <v-row align="center" justify="center" class="pt-5 pb-5 pe-2 ps-2">
              <v-col sm="4" cols="4">
                <v-row>
                  <v-col sm="6" cols="6" v-if="logged_in">
                    <v-app-bar-nav-icon
                      height="40"
                      depressed
                      fab
                      large
                      class="float-left"
                      @click.stop="drawer = !drawer"
                      @click="$emit('handle-show-off')"
                    ></v-app-bar-nav-icon>
                  </v-col>
                  <v-col sm="6" cols="6">
                    <v-btn
                      icon
                      class="pa-2 text-h6 font-weight-medium"
                      @click="$emit('handle-show')"
                    >
                      <v-img
                        width="35"
                        src="@/assets/iconsdashboard/course-icon.svg"
                      ></v-img>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="4" cols="2" align="center">
                <v-img
                  alt="nextgrade Logo"
                  class="align-center"
                  contain
                  src="@/assets/iconsdashboard/nexrgrade-logo-svg.svg"
                  transition="scale-transition"
                  width="80"
                  @click="
                    $router.push('/').catch((e) => {}), $emit('handle-show-off')
                  "
                />
              </v-col>
              <v-col sm="4" cols="6">
                <template>
                  <v-row align="center" justify="center" v-if="logged_in">
                    <v-col lg="4" cols="3" align="center">
                      <v-btn icon depressed @click="$emit('handle-search')">
                        <v-avatar size="auto">
                          <v-img
                            src="@/assets/iconsdashboard/search-icon.svg"
                          ></v-img>
                        </v-avatar>
                      </v-btn>
                    </v-col>
                    <v-col lg="4" cols="3"
                      align="center"
                      v-if="
                        is_student &&
                        $store.state.student.cust_client_id > 1 &&
                        $store.state.student.location_id > 1
                      "
                    >
                      <v-menu
                        min-height="560"
                      
                        left
                        rounded
                        :close-on-content-click="hideNotifcation"
                        max-width="330"
                        :offset-y="offset"
                      >
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            icon
                            depressed
                            @click="$emit('handle-show-off')"
                            v-bind="attrs"
                            v-on="on"
                            class="me-sm-0 pe-1"
                          >
                            <v-avatar size="auto" @click="listNotification(), hideNotifcation = false">
                              <v-img
                                src="@/assets/iconsdashboard/notification-icon.svg"
                              ></v-img>
                            </v-avatar>

                            <v-badge
                              :content="messages"
                              :value="messages"
                              color="red"
                              overlap
                              class="mt-n8"
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-badge>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card flat width="400">
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="d-flex justify-space-between"
                                  >
                                    <div class="d-flex">
                                      <h3 style="font-size: 18px">
                                        Notification
                                      </h3>
                                      <span width="300" class="mt-n3 ms-0" style="cursor:pointer">
                                      <v-select
                                        v-model="selected_notification_type"
                                        :items="notification_read"
                                        selected="All"
                                        color="#2C3E50" 
                                        style="box-shadow: 0px !important"
                                        solo
                                        flat
                                        @change="listNotification(selected_notification_type)"
                                      >
                                      </v-select>
                                      </span>
                                    </div>
                                    <span 
                                    class="ps-0 mt-1 text-capitalize"
                                    >
                                    <h6
                                      style="
                                        color: #1a1f36;
                                        font-weight: 400;
                                        font-size: 12px;
                                        cursor:pointer
                                      "
                                       @click="markAsReadAll()"
                                      class="ps-0"
                                    >
                                      Mark all as read
                                      <v-icon
                                        small
                                        color="#1A1F36"
                                        class="mt-n1"
                                        >mdi-check-circle-outline</v-icon
                                      >
                                    </h6>
                                  </span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                          <div class="notification-scroll">
                            <v-card
                              class="pa-5"
                              flat
                              style="cursor: pointer"
                              v-for="notification in notificationsList"
                              :key="notification.id"
                              @click="markAsRead(notification), hideNotifcation = true"
                            >
                              <v-row>
                                <v-col lg="2" sm="2" cols="2">
                                  <v-badge
                                    v-if="
                                      notification.notification_user_view != 1
                                    "
                                    color="#F39C12"
                                    dot
                                    style="
                                      width: 5px !important;
                                      height: 5px !important;
                                    "
                                  ></v-badge>
                                  <v-avatar
                                    class="ms-5 mt-n2"
                                    size="50"
                                    color="grey"
                                  >
                                  <v-img v-if="notification.sender_img"
                                    :src="APP_URL+notification.sender_img"
                                  ></v-img>
                                  <span v-else class="white--text text-h5">{{notification.sneder_name[0]}}</span>
                                  </v-avatar>
                                </v-col>
                                <v-col lg="10" sm="10" cols="10">
                                  <h3 class="mt-4 ps-8" style="font-size: 15px">
                                    <!-- Lecture -->
                                    <span style="font-weight: 200">{{
                                      notification.notification_body
                                    }}</span>
                                    <!-- Lesson 1 -->
                                  </h3>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col lg="12" cols="12">
                                  <h4
                                    style="
                                      font-size: 14px;
                                      line-height: 20px;
                                      color: #a5acb8;
                                      padding-left: 30%;
                                    "
                                    class="mt-n2"
                                  >
                                    {{ notification.notify_time }}
                                  </h4>
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-divider></v-divider>
                            <v-card
                              class="pa-10"
                              flat
                              style="cursor: pointer"
                              v-if="notificationsList.length == 0"
                            >
                              <h2
                                style="
                                  font-size: 18px;
                                  line-height: 20px;
                                  color: #f39c12;
                                  text-align: center;
                                "
                              >
                                No Notifications Yet!
                              </h2>
                              <div class="mt-6" align="center">
                                <v-img
                                  width="100"
                                  src="@/assets/iconsdashboard/bell.svg"
                                ></v-img>
                              </div>
                            </v-card>
                          </div>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col lg="4" cols="3"
                      :class="
                        $store.state.student.cust_client_id > 1 ? '' : 'ml-5'
                      "
                      align="center"
                    >
                      <v-menu
                        max-width="240px"
                        left
                        bottom
                        rounded
                        :offset-y="offset"
                        class="pa-5"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            @click="$emit('handle-show-off')"
                          >
                          <v-avatar color="#2C3E50" size="36">
                                <img v-if="$store.state.student_img" :src="`${APP_URL}${$store.state.student_img}`" alt="" />
                                <h3 v-else class="white--text">
                                  {{ $store.state.student_name[0] }}
                                </h3>
                              </v-avatar>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list-item-content class="justify-left">
                            <div class="mx-auto text-center">
                              <div class="d-flex justify-space-between pa-5">
                                <v-avatar color="#2C3E50" size="36">
                                <img v-if="$store.state.student_img" :src="`${APP_URL}${$store.state.student_img}`" alt="" />
                                <h3 v-else class="white--text">
                                  {{ $store.state.student_name[0] }}
                                </h3>
                              </v-avatar>
                                <h3 class="ps-2 pt-0" style="line-height:17px; max-width: 75%;">
                                  {{ $store.state.student_name }} <br />
                                  <span style="color: #888888; font-size: 12px">
                                    {{ $store.state.student.location_name }} |
                                    {{ $store.state.student.client_name }}
                                  </span>
                                </h3>
                              </div>
                              <v-divider class="my-3"></v-divider>
                              <v-card flat class="ps-5 pe-5">
                                <v-list>
                                  <v-list-item-group>
                                    <v-list-item
                                      @click="
                                     is_student ? $router
                                        .push('/dashboard/myprofile')
                                        .catch((e) => {}) :
                                        $router
                                        .push('/teacher/dashboard/profile')
                                        .catch((e) => {})
                                    "
                                    >
                                      <v-list-item-icon>
                                        <v-img
                                          src="@/assets/iconsdashboard/settings.svg"
                                        >
                                        </v-img>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          style="
                                            font-size: 18px;
                                            text-align: start;
                                          "
                                        >
                                          Settings
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="my-0"></v-divider>
                                    <v-list-item
                                      v-if="
                                        is_student &&
                                        $store.state.student.cust_client_id ==
                                          1 &&
                                        $store.state.student.location_id == 1
                                      "
                                      @click.stop="
                                        showDialog($store.state.student_id)
                                      "
                                    >
                                      <v-list-item-icon>
                                        <v-icon v-text="'mdi-account-remove'">
                                        </v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          style="
                                            font-size: 18px;
                                            text-align: start;
                                          "
                                          v-text="'Delete Account'"
                                        >
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="my-0"></v-divider>

                                    <v-list-item @click="logout()">
                                      <v-list-item-icon>
                                        <v-img
                                          src="@/assets/iconsdashboard/log-out.svg"
                                        ></v-img>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          style="
                                            font-size: 18px;
                                            text-align: start;
                                          "
                                          v-text="'Logout'"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="my-0"></v-divider>
                                  </v-list-item-group>
                                </v-list>
                              </v-card>
                            </div>
                          </v-list-item-content>
                        </v-card>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center" v-else>
                    <v-col lg="4" cols="1" >
                      <v-btn icon depressed @click="$emit('handle-search')">
                        <v-avatar size="30">
                          <v-img
                            src="@/assets/iconsdashboard/search-icon.svg"
                          ></v-img>
                        </v-avatar>
                      </v-btn>
                    </v-col>
                    <v-col lg="4" cols="5">
                      <v-btn
                        text
                        class="text-capitalize"
                        color="#2C3E50"
                        style="font-size: 10px"
                        @click="$router.push('/login').catch((e) => {})"
                      >
                        Login
                      </v-btn>
                    </v-col>
                    <v-col lg="4" cols="5">
                      <v-btn
                        dark
                        class="text-capitalize ms-n3"
                        color="#F39C12"
                        style="font-size: 10px"
                        @click="$router.push('/signup').catch((e) => {})"
                      >
                        Sign Up
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </div>
    </v-app>

    <!-- tablet and moblie screen -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="text-h5"> Delete Account </v-card-title>
            <v-card-text>
              Are you sure you want to delete?
              <!-- <br />
                You will loss your all progress in this course -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn color="green darken-1" text @click="deleteAccount()">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import { HTTP } from "../common/common-http";
import { HTTP_TC } from "../common/common-http-teacher";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
export default {
  name: "App",

  data: () => ({
    // expand: false,
    Courseexpend: false,
    loading: false,
    loader: null,
    show: false,
    drawer: false,
    showMenu: false,
    messages: 0,
    offset: true,
    idForDel: 0,
    dialog: false,
    device_token: "",
    notificationsList: [],
    selected_notification_type:"All",
    notification_read:["All", "Read", "Unread"],
    // is_student: localStorage.getItem("studentaccesstoken"),
    // selectedItem: 1,
    model: 1,
    items: [
      { text: "Home", icon: "mdi-home", link: "/dashboard/courses" },
      // {
      //   text: "Settings",
      //   icon: "mdi-account-settings-outline",
      //   link: "/dashboard/courses",
      // },
      // {
      //   text: "Help",
      //   icon: "mdi-comment-question",
      //   link: "/dashboard/courses",
      // },
    ],
    menu: [
      "dashboard",
      "courses",
      "public",
      "categories",
      "question",
      "student",
      "exam",
      "exams",
      "quiz",
      "practice",
      "profile",
      "changepassword",
    ],
    material_draw: false,
    APP_URL: process.env.VUE_APP_ROOT_URL,
    hideNotifcation:false
  }),
  computed: {
    logged_in() {
      return this.$store.state.logged_in;
    },
    student_name() {
      return this.$store.state.student_name;
    },
    is_student(){
      return this.$store.state.is_student;

    }
  },
  created() {
    // this.fetchCourses();
    if (
      this.is_student &&
      this.$store.state.student_client_id > 1 &&
      this.$store.state.student_location_id > 1
    ) {
      this.Notification();
      this.fcmToken();
      this.reMessage();
    }

 

    
  },
  methods: {
    showDialog(id) {
      this.dialog = true;
      this.idForDel = id;
    },
    async deleteAccount() {
      try {
        HTTP.delete(`auth/delete/studentAccount`, {
          student_id: this.idForDel,
        }).then((response) => {
          if (response.data.status === "Success") {
            // this.errors = [];
            this.dialog = false;
            localStorage.removeItem("studentaccesstoken");
            localStorage.removeItem("std_name");
            localStorage.removeItem("std_id");
            localStorage.removeItem("student_img");
            this.$store.state.logged_in = false;
            this.$store.state.student_name = "";
            this.$store.state.student_id = "";
            this.$store.state.student = "";
            this.$store.state.student_img = "";
            this.$router.push("/login");
            // this.idForDel = 0;
          }
        });
      } catch (e) {
        this.errors.push(e);
      }
    },
    async logout() {
      if (localStorage.getItem("studentaccesstoken")) {
        await HTTP.post(`auth/logout`)
          .then((response) => {
            // {device_token: this.device_token,} add this in request for deleting token
            if (response.data.status === "Success") {
              localStorage.removeItem("studentaccesstoken");
              localStorage.removeItem("std_name");
              localStorage.removeItem("std_id");
              localStorage.removeItem("student_img");
              localStorage.removeItem("location_id");
              localStorage.removeItem("location_name");
              localStorage.removeItem("client_id");
              localStorage.removeItem("client_name");
              this.$store.state.logged_in = false;
              this.$store.state.student_name = "";
              this.$store.state.student_id = "";
              this.$store.state.student = "";
              this.$store.state.student_img = "";
              this.$store.state.is_student = ""
              this.$router.push("/login");
            } else if (response.data.status === "Error") {
              this.errors = response.data.message;
            } else {
              this.errors = response.data.errors;
            }
          })
          .catch((err) => {
            // this.$swal("Error!", `${err.response.data.message}`, "error");
            // console.log(err.response.data.message, "errors");
            console.log(err.response.data);
            let message = Object.prototype.hasOwnProperty.call(
              err.response.data,
              "message"
            );
            if (message) {
              // this.$swal("Error!", `Please Login `, "error");
              localStorage.removeItem("studentaccesstoken");
              localStorage.removeItem("std_name");
              localStorage.removeItem("std_id");
              this.$router.push("/login");
            }
          })
          .finally(() => {});
      } else {
        await HTTP_TC.post(`auth/logout`)
          .then((response) => {
            // {device_token: this.device_token,} add this in request for deleting token
            if (response.data.status === "Success") {
              localStorage.removeItem("teacheraccesstoken");
              localStorage.removeItem("std_name");
              localStorage.removeItem("std_id");
              localStorage.removeItem("student_img");
              localStorage.removeItem("location_id");
              localStorage.removeItem("location_name");
              localStorage.removeItem("client_id");
              localStorage.removeItem("client_name");
              this.$store.state.logged_in = false;
              this.$store.state.student_name = "";
              this.$store.state.student_id = "";
              this.$store.state.student = "";
              this.$store.state.student_img = "";
              this.$router.push("/login");
            } else if (response.data.status === "Error") {
              this.errors = response.data.message;
            } else {
              this.errors = response.data.errors;
            }
          })
          .catch((err) => {
            // this.$swal("Error!", `${err.response.data.message}`, "error");
            // console.log(err.response.data.message, "errors");
            console.log(err.response.data);
            let message = Object.prototype.hasOwnProperty.call(
              err.response.data,
              "message"
            );
            if (message) {
              // this.$swal("Error!", `Please Login `, "error");
              localStorage.removeItem("studentaccesstoken");
              localStorage.removeItem("std_name");
              localStorage.removeItem("std_id");
              this.$router.push("/login");
            }
          })
          .finally(() => {});
      }
    },
    fcmToken() {
      const messaging = getMessaging();
      getToken(messaging, {
        vapidKey:
          "BHQefljJ8RupUY3opdlU-qnSseyQenVc3LigP9BpmytJWiEPDgTy02RmcW0WRNvi7nSA5lElrA4wz8yhYwqOlpA",
      })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // console.log(currentToken, "you");
            this.device_token = currentToken;
          } else {
            // Show permission request UI
            console.error(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    },
    // For Foreground messages
    reMessage() {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: true,
          confirmButtonText: "View",
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "info",
          title: payload.data.body,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push(payload.data.screen).catch(() => {});
          }
        });
        this.messages++;
      });
    },
    listNotification(selected_notification_type = 0) {
      let type = 0;
        if(selected_notification_type == 'Read'){
          type = 2
        }
        if(selected_notification_type == 'Unread'){
          type = 1
        }
      HTTP.get(`auth/notifications?user_view=${type}`)
        .then((response) => {
          // console.log(response.data.data, "you");
          if (response.data.status === "Success") {
            this.notificationsList = response.data.data;
            this.messages = 0;
          }
        })
        .catch((err) => {
          this.errors.push(err.response.data.message);
          console.log(this.errors, "errors");
        });
      // }
    },
    Notification() {
      // if (this.show) {
      HTTP.get(`auth/notifications`)
        .then((response) => {
          console.log(response.data.data, "you");
          if (response.data.status === "Success") {
            this.notificationsList = response.data.data;
            this.notificationsList.forEach((e) => {
              if (e.notification_user_view != 1) {
                console.log(this.messages, "notification_user_view");
                this.messages++;
              }
            });
            // this.messages = response.data.data.length
          }
        })
        .catch((err) => {
          this.errors.push(err.response.data.message);
          console.log(this.errors, "errors");
        });
      // }
    },
    markAsRead(notification) {
      // this.show = !this.show;
      HTTP.get(`auth/notification/${notification.id}`)
        .then((response) => {
          if (response.data.status === "Success") {
            this.$router.push(response.data.data.web_url).catch(() => {});

            if (notification.notification_type == 2) {
              this.$router.push("/").catch(() => {});
            } else {
              console.log(response.data.data.web_url);
              this.$router.push(response.data.data.web_url).catch(() => {});
            }
          }
        })
        .catch((err) => {
          this.errors.push(err.response.data.message);
          console.log(this.errors, "errors");
        });
    },
    markAsReadAll(){
      HTTP.get(`auth/notification/readAll`)
        .then((response) => {
          if (response.data.status === "Success") {
           this.listNotification(this.selected_notification_type);
          //  this.selected_notification_type = 'All'
          }
        })
        .catch((err) => {
          this.errors.push(err.response.data.message);
          console.log(this.errors, "errors");
        });
    }
  },
  watch: {
    
    '$store.state.student_client_id'(val){
      console.log('ssss',val,'sss')
      if(val > 1 && this.is_student){
     
        this.Notification();
        this.fcmToken();
        this.reMessage();
      
      }
    },

    '$store.state.student.cust_client_id': function() {
        
      console.log('here in navbar watch if', this.$store.state.student.cust_client_id,this.$vuetify.breakpoint.name)
    if(this.$vuetify.breakpoint.name=='sm' || this.$vuetify.breakpoint.name=='xs')
    {
    
          console.log('Navbar---SM-XS');
          let path = this.$route.path;
          path = path.replace(/\/$/, "");
          path = path.split("/");
          let selectIndex = 0;

         // console.log('navbar',path,path[path.length - 3])
          if (path[path.length - 3] == "material") {
          this.menu = ["units", "lessons", "lectures", "listing", "students"];
          this.drawer=false
          this.material_draw=true

          selectIndex = this.menu.indexOf(path[path.length - 2]);
          console.log('navbar',path,this.menu,selectIndex)

          this.model = selectIndex ;
          
          } else {
          
            console.log('here in navbar watch if---', this.$store.state.student.cust_client_id)
          if (
          this.$store.state.student.user_client_id > 1 &&
          this.$store.state.student.user_loc_id > 1
          ) {
           
          this.menu = [
          "dashboard",
          "courses",
          "public",
          "categories",
          "question",
          "student",
          "exam",
          "exams",
          "quiz",
          "practice",
          "profile",
          "changepassword",
          ];
          } else if (this.$store.state.student.user_client_id==1  && this.$store.state.student.user_loc_id==1 ) {
          
          console.log('else teacher ng')
            this.menu = [
          "dashboard",
          "public",
          "categories",
          "question",
          "student",
          "exam",
          "exams",
          "quiz",
          "practice",
          "profile",
          "changepassword",
          ];
          selectIndex = this.menu.indexOf(path[path.length - 1]);
          console.log('student selection',path,this.menu,selectIndex)
          this.model = selectIndex;
          }else if (this.$store.state.student.cust_client_id==1  && this.$store.state.student.location_id==1 ) {
          
          console.log('else student ng')
            this.menu = [
          "student",
          "other",
          "myprofile",
          "password",
          ];
          selectIndex = this.menu.indexOf(path[path.length - 1]);
          console.log('student selection',path,this.menu,selectIndex)
          this.model = selectIndex;
          } else if (this.$store.state.student.cust_client_id>1  && this.$store.state.student.location_id>1 ) {
          
          console.log('else student private')
            this.menu = [
          "student",
          "courses",
          "archive",
          "other",
          "previous",
          "upcoming",
          "available",
          "myprofile",
          "password",
         
          ];
          selectIndex = this.menu.indexOf(path[path.length - 1]);
          console.log('student selection',path,this.menu,selectIndex)
          this.model = selectIndex;
          }




         
          }

    }
           
    },

    '$store.state.student.user_client_id': function() {
        
        console.log('here in navbar watch if teacher', this.$store.state.student.user_client_id,this.$vuetify.breakpoint.name)
      if(this.$vuetify.breakpoint.name=='sm' || this.$vuetify.breakpoint.name=='xs')
      {
      
            console.log('Teacher Navbar---SM-XS');
            let path = this.$route.path;
            path = path.replace(/\/$/, "");
            path = path.split("/");
            let selectIndex = 0;
  
           // console.log('navbar',path,path[path.length - 3])
            if (path[path.length - 3] == "material") {
            this.menu = ["units", "lessons", "lectures", "listing", "students"];
            this.drawer=false
            this.material_draw=true
  
            selectIndex = this.menu.indexOf(path[path.length - 2]);
            console.log('navbar teacher material',path,this.menu,selectIndex)
  
            this.model = selectIndex ;
            
            } else {
            
            this.drawer=true
            this.material_draw=false
              console.log('here in navbar watch if teacher---', this.$store.state.student.user_client_id)
            if (
            this.$store.state.student.user_client_id > 1 &&
            this.$store.state.student.user_loc_id > 1
            ) {
              console.log('teacher school selection',path,this.menu,selectIndex)
            this.menu = [
            "dashboard",
            "courses",
            "public",
            "categories",
            "question",
            "student",
            "exam",
            "exams",
            "quiz",
            "practice",
            "profile",
            "changepassword",
            ];
            selectIndex = this.menu.indexOf(path[path.length - 1]);
            console.log('teacher selection',path,this.menu,selectIndex)
            this.model = selectIndex;
            } else if (this.$store.state.student.user_client_id==1  && this.$store.state.student.user_loc_id==1 ) {
            
            console.log('else teacher ng')
              this.menu = [
            "dashboard",
            "public",
            "categories",
            "question",
            "student",
            "exam",
            "exams",
            "quiz",
            "practice",
            "profile",
            "changepassword",
            ];
            selectIndex = this.menu.indexOf(path[path.length - 1]);
            console.log('teacher selection',path,this.menu,selectIndex)
            this.model = selectIndex;
            }
  
  
  
  
           
            }
  
      }
             
      },
    
    $route(val) {
     
      if(this.$vuetify.breakpoint.name=='sm' || this.$vuetify.breakpoint.name=='xs')
      {
        console.log('navbar watch')
       
        if (val.path.includes("/course/material")) {
          this.material_draw = true;
        } else {
          this.material_draw = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.navbar-large-screen {
  background: #ffffff !important;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1) !important;
  z-index: 1;
}
.btn-navbar {
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  text-transform: capitalize;
}
/* .mobile-screen{
  color: #ffffff;
} */
.mobile-screen {
  display: none;
}
.theme--light.v-label {
  color: #f39c12 !important;
}
.notification-scroll {
  overflow-y: scroll;
  height: 560px;
}
.notification-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.notification-scroll::-webkit-scrollbar {
  width: 8px;
}

.notification-scroll::-webkit-scrollbar-thumb {
  background: #dddee1;
  /* color of the scroll thumb */
  /* border-radius: 4px; */
  /* roundness of the scroll thumb */
  border: 2px solid #dddee1;
  /* creates padding around scroll thumb */
}

.math-text {
  display: inline-block;
  /* margin:10% 2%; */
}
/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
      background: #F39C12; 
    } */
/* .active, .activee-button{
  background: transparent;
  border-bottom:4px solid #F39C12 ;
}
.activee-button:hover{
  background: transparent;
} */
/* .activee-button:hover {
  transform: scale(1.5);
  opacity: 0.2;
  filter: blur(0);
} */
.v-list-item-group .v-list-item--active {
    background: #f39c12;
    color: white;
    border-radius: 6px;
}
@media (max-width: 960px) {
  .navbar-large-screen {
    z-index: 10;
  }
  .course-material-navigation {
    min-height: 900px;
  }
  .large-screen {
    display: none;
  }
  .mobile-screen {
    display: block;
  }
}
@media (max-width: 600px) {
  .course-material-navigation {
    min-height: 590px;
  }
}
</style>
<style scoped lang="scss">
::v-deep .v-application--wrap {
  min-height: fit-content;
}
</style>
