import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    courses_categories: [],
    footer_courses_categories: [],
    logged_in: localStorage.getItem("studentaccesstoken") ? localStorage.getItem("studentaccesstoken") : localStorage.getItem("teacheraccesstoken") ,
    student_name: localStorage.getItem("std_name"),
    student_id: localStorage.getItem("std_id"),
    student_img: localStorage.getItem("student_img"),
    student_location_name: localStorage.getItem("location_name"),
    student_client_name: localStorage.getItem("client_name"),
    student_client_id: localStorage.getItem("client_id"),
    student_location_id: localStorage.getItem("location_id"),
    student: "",
    // dashboard card record
    total_students: null,
    total_classes: null,
    teacher_exams: null,
    total_other_students: null,
    total_other_classes: null,
    teacher_other_exams: null,
    is_student: localStorage.getItem("studentaccesstoken"),
  },
  getters: {
    GET_TOTAL_STUDENTS(state) {
      return state.total_students;
    },
    GET_TOTAL_CLASSES(state) {
      return state.total_classes;
    },
    GET_TOTAL_EXAMS(state) {
      return state.teacher_exams;
    },

    GET_TOTAL_OTHER_STUDENTS(state) {
      return state.total_other_students;
    },
    GET_TOTAL_OTHER_CLASSES(state) {
      return state.total_other_classes;
    },
    GET_TOTAL_OTHER_EXAMS(state) {
      return state.teacher_other_exams;
    },
  },
  mutations: {
    Add_Courses_Categories(state, payload) {
      state.courses_categories = payload.courses_categories;
      state.courses_categories.forEach((element, i) => {
        if (i < 6) state.footer_courses_categories.push(element);
      });
    },
    Add_User(state, payload) {
      state.student = payload.student;
      
    },
    Add_Grades(state, payload) {
      state.grades = payload.grades;
  
    },
    Add_Teacher_Dashboard_Record(state, payload) {
      state.teacher_exams = payload.teacher_exams;
      state.total_classes = payload.total_classes;
      state.total_students = payload.total_students;

      state.teacher_other_exams = payload.teacher_other_exams;
      state.total_other_classes = payload.total_other_classes;
      state.total_other_students = payload.total_other_students;
    },
  },
  actions: {},
});
