import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { store } from "@/store/store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueYoutube from "vue-youtube";
import VueScrollTo from "vue-scrollto";
import DatetimePicker from 'vuetify-datetime-picker'
import ToggleButton from 'vue-js-toggle-button'
import "./firebase";


Vue.use(VueScrollTo);
Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(ToggleButton)
Vue.use(VueYoutube);
Vue.use(DatetimePicker)
new Vue({
  router,
  vuetify,
  VueSweetalert2,
  VueScrollTo,
  store,
  render: (h) => h(App),
}).$mount("#app");
