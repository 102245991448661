<template>
  <div @click="$emit('handle-show-off')">
    <div style="position: absolute; width: 100%; z-index: 5">
      <CourseMaterial v-if="courseShow" @handle-show-course="showCourse" />
    </div>
    <div class="mt-lg-6 mt-md-3 mt-0">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CourseMaterial from "./CourseMaterial.vue";
export default {
  name: "bodyComp",
  components: {
    CourseMaterial,
  },
  props: {
    courseExpend: Boolean,
  },
  data() {
    return {
      courseShow: false,
    };
  },
  methods: {
    showCourse() {
      this.courseShow = !this.courseShow;
    },
  },
  watch: {
    courseExpend(val) {
      this.courseShow = val;
    },
  },
};
</script>

<style scoped></style>
