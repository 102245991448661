<template>
  <div>
    <v-expand-transition >
      <v-card min-height="auto" width="100%" class="mx-auto #FFFFFF">
        <v-container>
          <v-row>

            <v-col  
              lg="3"
              md="4"
              sm="6"
              cols="12"
              class="course-list"
              >

              <div class="d-flex justify-space-between pt-5">
                <h4>Primary School</h4>
              </div>

              <ul>
                <li @click="$router.push(`/grade/2`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 2</li>
                <li @click="$router.push(`/grade/4`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 3</li>
                <li @click="$router.push(`/grade/5`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 4</li>
                <li @click="$router.push(`/grade/6`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 5</li>
              </ul>
            
            </v-col>
            <v-col  
              lg="3"
              md="4"
              sm="6"
              cols="12"
              class="course-list"
              >

              <div class="d-flex justify-space-between pt-5">
                <h4>Middle School</h4>
              </div>

              <ul>
                <li @click="$router.push(`/grade/7`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 6</li>
                <li @click="$router.push(`/grade/8`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 7</li>
                <li @click="$router.push(`/grade/9`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 8</li>
              </ul>
            
            </v-col>
            <v-col  
              lg="3"
              md="4"
              sm="6"
              cols="12"
              class="course-list"
              >

              <div class="d-flex justify-space-between pt-5">
                <h4>High School</h4>
              </div>
                <ul>
                <li @click="$router.push(`/grade/10`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 9</li>
                <li @click="$router.push(`/grade/11`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 10</li>
              </ul>
            
            </v-col>
            <v-col  
              lg="3"
              md="4"
              sm="6"
              cols="12"
              class="course-list"
              >

              <div class="d-flex justify-space-between pt-5">
                <h4>Intermediate</h4>
              </div>

              <ul>
                <li @click="$router.push(`/grade/12`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 11</li>
                <li @click="$router.push(`/grade/13`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer">Grade 12</li>
              </ul>
            
            </v-col>

      
            
            
            <!-- <v-col
              lg="3"
              md="4"
              sm="6"
              cols="12"
              class="course-list"
              v-for="(grade, i) in grades"
              :key="i"
            >
              <div class="d-flex justify-space-between pt-5">
                <h4>{{ grade.name }}</h4>
              </div>

              <ul>
                <li v-for="(course, j) in grade.classes" :key="j" @click="$emit('handle-show-course')">
                  <router-link :to="`/course/${course.class_id}`" color="#00000">
                    {{ course.class_name }}
                    <span class="paid-course" v-if="course.is_paid == 1">
                      Paid
                    </span>
                  </router-link>
                </li>
              </ul>
            </v-col> -->

            <v-col
              lg="3"
              md="4"
              sm="6"
              cols="12"
              class="course-list"
              v-for="(category, i) in categories"
              :key="i"
            >
              <div class="d-flex justify-space-between pt-5">
                <h4 @click="$router.push(`/category/${category.id}`).catch(e=>{}),$emit('handle-show-course')" style="cursor: pointer" class="text-capitalize">
                  {{ category.cat_name }}
                </h4>
              </div>
              <ul>
                <li v-for="(course, j) in category.classes" :key="j" @click="$emit('handle-show-course')">
                  <router-link :to="`/course/${course.class_id}`"  color="#00000" class="text-capitalize">
                    {{ course.class_name }}
                    <span class="paid-course subtitle-1 font-weight-medium" v-if="course.is_paid == 1">
                      Paid
                    </span>
                  </router-link>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import { HTTP } from "../common/common-http";
import "@/assets/coursemeterial.css";

export default {
  name: "CourseMeterail",
  data: () => ({}),
  computed: {
    categories() {
      // getting courses categories from vue store
      if (this.$store.state.courses_categories)
        return this.$store.state.courses_categories;
      else return [];
    },

    grades() {
      // getting courses categories from vue store
      if (this.$store.state.grades)
        return this.$store.state.grades;
      else return [];
    },
  },
  
  methods: {
    async getGrades() {
      await HTTP.get(`grades`)
        .then((response) => {
          console.log("Get Grades Get Grades", response.data);
          // if (response.data.status === "Success") {
          //   console.log(response.data)
          //   this.checkEnrrollment()
          // }
        })
        .catch((err) => {
          // this.$swal("Error!", `${err.response.data.message}`, "error");
          this.skeleton = false;
          console.log(err.response.data.message, "errors");
        })
        .finally(() => {
          this.skeleton = false;
        });
    },
  },
  created() {
    // this.getGrades();
    // if(localStorage.getItem("studentaccesstoken")){
    //   this.getProgress()
    // }
    
  },


};
</script>
