import axios from "axios";
const APP_URL = process.env.VUE_APP_ROOT_URL;
const baseURL = `${APP_URL}/api/`;

const headers = {
    'Content-Type': 'application/json',
    'withCredentials': true,

}
export const HTTP_TC = axios.create({
    baseURL,
    headers: headers
});
HTTP_TC.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('teacheraccesstoken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);