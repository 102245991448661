import Vue from "vue";
import VueRouter from "vue-router";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";

Vue.use(VueRouter);
function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  // this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (localStorage.getItem("studentaccesstoken")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login';
  }
}

// for guarding teacher
function guardMyrouteTeacher(to, from, next) {
  var isAuthenticated = false;
  // this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (localStorage.getItem("teacheraccesstoken")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login';
  }
}
function is_authenticated(to, from, next) {
  var isAuthenticated = false;
  // this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (localStorage.getItem("studentaccesstoken") || localStorage.getItem("teacheraccesstoken")) isAuthenticated = true;
  else isAuthenticated = false;
  if (!isAuthenticated) {
    next(); // allow to enter route
  } else {
    if (localStorage.getItem("studentaccesstoken")){
        next("/dashboard/student"); // always go to student dashboard';
    }else{
      next("/teacher/dashboard"); // always go to teacher dashboard;
    }
  }
}
const routes = [
  // =============================================================================
  //     Landing pages
  // =============================================================================
  {
    path: "/",
    component: () => import("@/components/Body.vue"), // add layout component name
    children: [
      {
        path: "",
        name: "WelcomeComp",
        beforeEnter: is_authenticated,
        component: () => import("@/components/WelcomeComp.vue"),
      },
      {
        path: "course/:course_id",
        name: "courseView",
        component: () => import("@/components/CourseView.vue"),
      },
      {
        path: "grade/:grade_id",
        name: "courseGradeView",
        component: () => import("@/components/CourseGradeView.vue"),
      },
      {
        path: "course/unit/:unit_id",
        name: "courseUnitComp",
        // beforeEnter: guardMyroute,
        component: () => import("@/components/CourseUnitComp.vue"),
      },
      // routes for static pages
      {
        path: "about",
        name: "aboutComp",
        component: () => import("@/components/LandingPages/aboutpage/AboutComp.vue"),
      },
      {
        path: "news",
        name: "mainNesComp",
        component: () => import("@/components/LandingPages/aboutpage/newpages/MainNewsComp.vue"),
      },
      {
        path: "impact",
        name: "impactComp",
        component: () => import("@/components/LandingPages/impactlandingpages/MainImpactComp.vue"),
      },
      {
        path: "content/specialist",
        name: "contentSpecialistComp",
        component: () => import("@/components/LandingPages/ourcontentspelist/OurContentSpecialistsComp.vue"),
      },
      {
        path: "leadership",
        name: "LeadershipComp",
        component: () => import("@/components/LandingPages/ourleadership/OurLeaderShip.vue"),
      },


    
    ],
  },

  // ****************************************************************************
  //    Landing pages Ends
  // ****************************************************************************
  //
  //
  //
  //
  //      Add sign in, sign up, forget password etc in Take Auth section
  //
  // =============================================================================
  //      Take Auth Routes
  // =============================================================================

  {
    path: "/login",
    name: "logincomp",
    beforeEnter: is_authenticated,
    component: () => import("@/components/loginMainComp.vue"),
  },
  {
    path: "/student/login",
    name: "loginStudentcomp",
    beforeEnter: is_authenticated,
    component: () => import("@/components/LoginComp.vue"), 
  },
  {
    path: "/teacher/login",
    name: "loginTeachercomp",
    beforeEnter: is_authenticated,
    component: () => import("@/components/LoginTeacherComp.vue"),
  },
  {
    path: "/signup",
    name: "signUpcomp",
    beforeEnter: is_authenticated,
    component: () => import("@/components/SignUpComp.vue"),
  },
  {
    path: "/signup/student",
    name: "signUpStudentComp",
    beforeEnter: is_authenticated,
    component: () => import("@/components/SignUpStudentComp.vue"),
  },
  {
    path: "/signup/teacher",
    name: "signUpTeacherComp",
    beforeEnter: is_authenticated,
    component: () => import("@/components/SignUpTeacher.vue"),
  },
  {
    path: "/forgot/password",
    name: "ForgotPassword",
    beforeEnter: is_authenticated,
    component: () => import("@/components/ForgotPasswordComp.vue"),
  },
  {
    path: "/confirm/pin",
    name: "ConfirmPinComp",
    beforeEnter: is_authenticated,
    component: () => import("@/components/ConfirmPinComp.vue"),
  },
  {
    path: "/change/password",
    name: "ChangePasswordComp",
    beforeEnter: is_authenticated,
    component: () => import("@/components/ChangePasswordComp.vue"),
  },
  // teachre forget Password
  {
    path: "/teacher/forgot/password",
    name: "TeacherForgotPassword",
    beforeEnter: is_authenticated,
    component: () => import("@/components/teacherDashboard/ChangePassword/TeacherForgotPassword.vue"),
  },
  {
  path: "/teacher/change/password/:token/:email",
  name: "TeacherChangePasswordComp",
  beforeEnter: is_authenticated,
  component: () => import("@/components/teacherDashboard/ChangePassword/TeacherChangePasswordComp.vue"),
  },
  {
    path: "/category/:id",
    name: "CategoryComp",
    component: () => import("@/components/CategoryComp"),
  },
  {
    path: "/student/message/:message",
    name: "StudentMessage",
    component: () => import("@/components/StudentMessage"),
  },
  {
    path: "/teacher/message/:message",
    name: "teacherMessage",
    component: () => import("@/components/TeacherMessage"),
  },

  // Test screen
  {
    path: "/ratecourse",
    name: "rateThisCourse",
    component: () => import("@/components/RateThisCourseComp.vue"),
  },
 

  // tested
  // {
  //   path: "/course",
  //   name: "courseView",
  //   component: () => import("@/components/CourseView.vue"),
  // },
  // {
  //   path: "/course/unit",
  //   name: "courseUnitComp",
  //   component: () => import("@/components/CourseUnitComp.vue"),
  // },

  // ****************************************************************************
  //    Take Auth  Ends
  // ****************************************************************************
  //
  //
  //
  //
  //      Add All the routes which required auth tokens or authentications in
  //      Guarded Routes Sections
  // =============================================================================
  //    Guarded Routes
  // =============================================================================
  {
    path: "/dashboard",
    component: () =>
      import("@/components/studentDashboard/StudentDashboardComp.vue"),
    // component: () => import("@/components/-----"), // add layout component name
    beforeEnter: guardMyroute,
    children: [
      {
        path: "student",
        name: "StudentDashboard",
        component: () =>
          import("@/components/studentDashboard/StudentDashboard.vue"),
      },
      {
        path: "courses",
        name: "MyCoursesComp",
        component: () =>
          import("@/components/studentDashboard/CoursesComp.vue"),
      },
      {
        path: "courses/archive",
        name: "ArchiveCoursesComp",
        component: () =>
          import("@/components/studentDashboard/ArchiveCoursesComp.vue"),
      },
      {
        path: "courses/other",
        name: "OtherCoursesComp",
        component: () =>
          import("@/components/studentDashboard/OtherCoursesComp.vue"),
      },
      {
        path: "exams/available",
        name: "AvailableExams",
        component: () => import("@/components/studentDashboard/Exams/AvailableExams.vue"),
      },
      {
        path: "exams/previous",
        name: "PreviousExams",
        component: () => import("@/components/studentDashboard/Exams/PreviousExams.vue"),
      },
      {
        path: "exams/upcoming",
        name: "UpcomingExams",
        component: () => import("@/components/studentDashboard/Exams/UpcomingExams.vue"),
      },
      {
        path: "myprofile",
        name: "myProfileComp",
        component: () =>
          import("@/components/studentDashboard/MyProfileComp.vue"),
      },
      // {
      //   path: "payment",
      //   name: "paymentMethodComp",
      //   component: () =>
      //     import("@/components/studentDashboard/PaymentMehtodDashComp.vue"),
      // },
      {
        path: "change/password",
        name: "changePassword",
        component: () =>
          import("@/components/studentDashboard/ChangePasswordDashboard.vue"),
      },
    
      // =============================================================================
      //    Teste protected routes
      // =============================================================================
    ],
    // exam detail
  },
  {
    path: "/exam/detail/:exam_id",
    name: "examDetail",
    beforeEnter: guardMyroute,
    component: () =>
      import("@/components/Lms/Exam/ExamDetailComp.vue"),
  },

  // for teachers 
  {
    path: "/teacher/dashboard",
    component: () =>
      import("@/components/teacherDashboard/TeacherDashboardComp.vue"),
    // component: () => import("@/components/-----"), // add layout component name
    beforeEnter: guardMyrouteTeacher,
    children: [

       // dashboard
       {
        path: "/",
        name: "TeacherDashboard",
        component: () =>
          import("@/components/teacherDashboard/TeacherDashboard.vue"),
      },
      // My Stuff
      {
        path: "courses",
        name: "CourseTeacherPrivate",
        component: () =>
          import("@/components/teacherDashboard/CourseTeacherPrivate.vue"),
      },
      {
        path: "courses/public",
        name: "courseTeacher",
        component: () =>
          import("@/components/teacherDashboard/CourseTeacher.vue"),
      },
      // Question Categoires
      {
        path: "question/categories",
        name: "QuestionCategoriesComp",
        component: () =>
          import("@/components/teacherDashboard/Questions/QuestionCategoriesComp.vue"),
      },
      {
        path: "question/category/add",
        name: "createCategoryComp",
        component: () =>
          import("@/components/teacherDashboard/Questions/createCategoryComp.vue"),
      },
      {
      path: 'question/category/update/:id',
      name: 'CategoryUpdate',
        component: () => import("@/components/teacherDashboard/Questions/updateCategoryComp.vue"),
      },
      
      // questions 
      {
        path: "question",
        name: "QuestionComp",
        component: () =>
          import("@/components/teacherDashboard/Questions/QuestionComp.vue"),
      },
      {
        path: "question/add",
        name: "createQuestionComp",
        component: () =>
          import("@/components/teacherDashboard/Questions/createQuestionComp.vue"),
      },
      {
      path: 'question/update/:id',
      name: 'updateQuestionComp',
        component: () => import("@/components/teacherDashboard/Questions/updateQuestionComp.vue"),
      },

      // Student 
      {
        path: "students",
        name: "StudentsMainComp",
        component: () =>
          import("@/components/teacherDashboard/Students/StudentsMainComp.vue"),
      },
      {
        path: "student/add",
        name: "createStudentComp",
        component: () =>
          import("@/components/teacherDashboard/Students/createStudentComp.vue"),
      },
      {
      path: 'student/update/:id',
      name: 'updateStudentComp',
        component: () => import("@/components/teacherDashboard/Students/updateStudentComp.vue"),
      },
      {
        path: 'student/exams/:id',
        name: 'StudentExams',
          component: () => import("@/components/teacherDashboard/Students/examslist/StudentExams.vue"),
      },
      // Exams 
      {
        path: "exam",
        name: "examComp",
        component: () =>
          import("@/components/teacherDashboard/Exam/ExamComp.vue"),
      },
      {
        path: "exam/add",
        name: "createExamComp",
        component: () =>
          import("@/components/teacherDashboard/Exam/createExamComp.vue"),
      },
      {
        path: 'exam/update/:id',
        name: 'EditExamTemplate',
        component: () => import("@/components/teacherDashboard/Exam/createExamComp.vue"),
      },
      // Assign Exams 
      {
        path: "assigned/exams",
        name: "AssignedExam",
        component: () =>
          import("@/components/teacherDashboard/AssignExam/AssignedExam.vue"),
      },
      {
        path: "assign/exam",
        name: "assignExamComp",
        component: () =>
          import("@/components/teacherDashboard/AssignExam/assignExamComp.vue"),
      },
      {
        path: 'assign/exam/update/:id',
        name: 'updateExamComp',
        component: () => import("@/components/teacherDashboard/AssignExam/updateExamComp.vue"),
      },
      {
        path: 'exam/student/:id',
        name: 'examStudentsListComp',
        component: () => import("@/components/teacherDashboard/AssignExam/listStudents/examStudentsListComp.vue"),
      },
      {
        path: 'exam/detail/:cust_id/:exam_id',
        name: 'editAssignExamComp',
        component: () => import("@/components/teacherDashboard/AssignExam/examDetailComp.vue"),
      },
      {
        path: 'assign/exam/written/print/:id',
        name: 'examAssignReprintComp',
        component: () => import('@/components/teacherDashboard/AssignExam/examAssignRePrintComp')
    },

      // quiz assignment
      {
        path: "assigned/quiz",
        name: "QuizExam",
        component: () =>
          import("@/components/teacherDashboard/AssignQuiz/AssignedQuiz.vue"),
      },
      {
        path: "assign/quiz",
        name: "QuizExamComp",
        component: () =>
          import("@/components/teacherDashboard/AssignQuiz/assignQuizComp.vue"),
      },

      // Practice assignment
      {
        path: "assigned/practice",
        name: "PracticeExam",
        component: () =>
          import("@/components/teacherDashboard/AssignPractice/assignedPractice.vue"),
      },
      {
        path: "assign/practice",
        name: "PracticeExamComp",
        component: () =>
          import("@/components/teacherDashboard/AssignPractice/assignPracticeComp.vue"),
      },
     
      // MY Account
      {
        path: "profile",
        name: "profileTeacher",
        component: () =>
          import("@/components/teacherDashboard/ProfileTeacher.vue"),
      },
      {
        path: "changepassword",
        name: "changePasswordTeacher",
        component: () =>
          import("@/components/teacherDashboard/ChangePasswordTeacher.vue"),
      },
    ],
  },
   // for teachers courses material
   {
    path: "/course/material/",
    component: () => import("@/components/teacherDashboard/CourseMaterial/CourseMaterialLayoutComp.vue"),
    beforeEnter: guardMyrouteTeacher,
    children: [
      {
        path: "units/listing/:course_id",
        name: "UnitsComp",
        component: () =>
          import("@/components/teacherDashboard/CourseMaterial/UnitsComp.vue"),
      },
      {
        path: "students/:course_id",
        name: "StudentsComp",
        component: () =>
          import("@/components/teacherDashboard/CourseMaterial/CourseStudentComp.vue"),
      },
      {
        path: "quiz/:unit_id/:course_id",
        name: "QuizComp",
        component: () =>
          import("@/components/teacherDashboard/CourseMaterial/QuizComp.vue"),
      },
      {
        path: "practice/:lesson_id/:course_id",
        name: "PracticeComp",
        component: () =>
          import("@/components/teacherDashboard/CourseMaterial/PracticeComp.vue"),
      },

      // routes for material tabs
      {
        path: "units/:course_id",
        name: "UnitsListingComp",
        component: () =>
          import("@/components/teacherDashboard/CourseMaterial/UnitsListingComp.vue"),
      },
      {
        path: "lessons/:course_id",
        name: "LessonsListingComp.vue",
        component: () =>
          import("@/components/teacherDashboard/CourseMaterial/LessonsListingComp.vue"),
      },
      {
        path: "lectures/:course_id",
        name: "LecturesListingComp",
        component: () =>
          import("@/components/teacherDashboard/CourseMaterial/LecturesListingComp.vue"),
      },
    ],
  },




  // end teachers
  {
    path: "/privacy/policy",
    name: "privacyPolicy",
    component:PrivacyPolicy,
    
  },
  // ****************************************************************************
  //    Guarded Routes Ends
  // ****************************************************************************
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

export default router;
