<template>
  <div>
    <v-container fluid class="footer-container-1 mt-0" dark  @click="$emit('handle-show-off'), $emit('handle-search-comp-off')">

        <v-row class="">
          <v-col lg="5" md="4" sm="6" cols="12">
            <v-card flat color="transparent" width="80" class="mb-6">
            <v-img src="@/assets/iconsdashboard/footer-nextgrade-logo.svg"></v-img>
        </v-card>
            <h1 class="heading-footer">
              We believe that education is the biggest equalizer that can help <br class="d-lg-block d-none"> create better societies of the future.
            </h1>
            <v-row>
              <v-col lg="8">
                <v-card flat color="transparent"  width="100%"  class="mt-16 card-social-icon">
            <v-row>
              <v-col lg="2" md="2" sm="2">
                <a href="https://www.facebook.com/NEXTGRADEPK" target="_blank" style="text-decoration: none;"><v-btn icon color="#FFFFFF">
                  <v-icon medium>mdi-facebook</v-icon>
                </v-btn></a>
              </v-col>
              <v-col lg="2" md="2" sm="2">
               <a href="https://mobile.twitter.com/nxgrade" target="_blank" style="text-decoration: none;"> <v-btn icon color="#FFFFFF">
                  <v-icon medium>mdi-twitter</v-icon>
                </v-btn></a>
              </v-col>
              <v-col lg="2" md="2" sm="2">
                <a href="https://www.linkedin.com/company/next-grade-pakistan/" target="_blank" style="text-decoration: none;"><v-btn icon  color="#FFFFFF">
                  <v-icon medium>mdi-linkedin</v-icon>
                </v-btn></a>
              </v-col>
              <v-col lg="2" md="2" sm="2">
                <a href="https://www.instagram.com/nextgradepk/" target="_blank" style="text-decoration:none;"><v-btn icon  color="#FFFFFF">
                  <v-icon medium>mdi-instagram</v-icon>
                </v-btn></a>
              </v-col>
              <v-col lg="2" md="2" sm="2">
                <a href="https://www.youtube.com/@nextgradepk" target="_blank" style="text-decoration:none;"><v-btn icon  color="#FFFFFF">
                  <v-icon large>mdi-youtube</v-icon>
                </v-btn></a>
              </v-col>
            </v-row>
            </v-card>
              </v-col>
              <v-col lg="4">
                <v-card flat color="transparent" width="100%"   class="mt-16 card-social-icon">
            <v-row>
              <v-col lg="6" md="2" sm="2">
                <a href="https://apps.apple.com/pk/app/next-grade/id1558668871" target="_blank" style="text-decoration: none;"><v-btn icon color="#FFFFFF">
                  <v-img
                      src="@/assets/app-store-icon.svg"
                      alt="something went wrong"
                    />
                </v-btn></a>
              </v-col>
              <v-col lg="6" md="2" sm="2">
               <a href="https://play.google.com/store/apps/details?id=com.tgi.nextgrade&hl=en&gl=US&pli=1" target="_blank" style="text-decoration: none;"> <v-btn icon color="#FFFFFF">
                <v-img
                      src="@/assets/play-store-icon.svg"
                      alt="something went wrong"
                    />
                </v-btn></a>
              </v-col>
            </v-row>
            </v-card>
              </v-col>
            </v-row>
              
            
            
            
        
          </v-col>
          <v-col lg="2" md="2" sm="6" cols="6" class="footer-link">
            <h1 
              style="
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;" 
              class="ms-5 pb-8">
              Pages
            </h1>
            <ul>
              <li>
                <router-link 
                to="/about"
                ><span class="static-page-link">About</span> </router-link>
              </li>
              <li>
                <router-link 
                to="/news"><span class="static-page-link">News</span></router-link>
              </li>
              <li>
                <router-link 
                to="/impact"><span class="static-page-link">Impact</span> </router-link>
              </li>
              <li>
                <router-link 
                to="/content/specialist"><span class="static-page-link">Our Content Specialists</span></router-link>
              </li>
              <li>
                <router-link to="/leadership"
                ><span class="static-page-link">Our leadership</span> </router-link>
              </li>
            </ul>
          </v-col>
          <v-col lg="2" md="2" sm="6" cols="6" class="footer-link">
            <h1    
            style="
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;" 
              class="ms-5 pb-8">Courses</h1>
            <ul>     
              <li v-for="(category, i) in categories" :key="i">
                <router-link 
                :to="`/category/${category.id}`"> 
                <span   class="static-page-link"> 
                  {{ category.cat_name }}
                  </span> 
                  </router-link>
              </li>
            </ul>
          </v-col>
          <v-col lg="3" md="4" sm="6">
            <h1    
            style="
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;" 
              class="ms-5 pb-8">Contact</h1>
            <v-card
              
              max-width="auto"
              flat
              color="#2C3E50"
            >
  <v-list color="#2C3E50">  
      <v-list-item-group
        class="mt-n5"
        color="#ffff"
      >
        <v-list-item>
          <v-list-item-icon>
              <v-img width="20" src="@/assets/iconsdashboard/footer-phone-icon.svg"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >
              <a href="tel:04232176412" 
                class="addres-page-link"
                
                >
                    (042) 32176412
                </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
              <v-img width="20" src="@/assets/iconsdashboard/footer-email-icon.svg"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >
              <h4><a href="mailto:info@nxgrade.com"  class="addres-page-link">
                info@nxgrade.com
              </a></h4>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
              <v-img width="20" src="@/assets/iconsdashboard/footer-address-icon.svg"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >
              <h4  class="addres-page-link">
                HALY Tower, DHA Phase 2, 
                <br>
                Lahore
              </h4>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col lg="12" cols="12">
            <v-container dark class="footer-container-2">
              <v-card flat tile width="100%" color="#2C3E50" >
                <!-- <v-divider></v-divider> -->
                <v-card-text class="white--text text-center"
                 style=" font-weight: 400;
                        font-size: 14px;
                        line-height: 30px;
                        color: #FFFFFF;">
                  © {{new Date().getFullYear()}} Next Grade<br />
                  <span  @click="$router.push('/privacy/policy').catch((e) => {})" style="cursor:pointer; margin:17px 0 0">Terms of use | Privacy Policy | Cookie Notice</span>
                </v-card-text>
              </v-card>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
import "@/assets/footer.css";
export default {
  name: "footerComp",
  data (){
    return{
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],
    }
  },
  computed: {
    categories() {
      // getting courses categories from vue store
      if (this.$store.state.footer_courses_categories)
        return this.$store.state.footer_courses_categories;
      else return [];
    },
  },
};
</script>

<style scoped></style>
 