<template>
  <div class="about pa-0">
    <div>
      <v-app-bar color="white accent-4 ps-16 pe-16" height="auto" dense flat>
        <v-row class="d-flex align-center">
          <!-- <v-col lg="1"></v-col> -->
          <v-col lg="1" md="2" sm="2" cols="3" class="pb-0">
            <v-icon
              aria-hidden="false"
              class="mb-md-3 mt-n5 mt-md-0 mb-0 ms-md-8  ps-0"
              style="cursor: pointer; color: #f39c12; font-weight: 900"
              x-large
              @click="$emit('handle-search-comp')"
            >
              mdi-close
            </v-icon>
          </v-col>
          <v-col lg="4" md="6" sm="10" cols="9" class="mt-5 pb-0">
            <v-text-field
              solo
              label="Search"
              append-icon="mdi-magnify"
              color="#2C3E50"
              style="color: #f39c12"
              background-color="#F5F5F5"
              autofocus
              elevation="0"
              v-model="text"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-divider></v-divider>
    </div>
    <!-- <v-main>
        <router-view />
      </v-main> -->

    <template v-if="skeleton == true">
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-skeleton-loader
              v-bind="attrs"
              type="image,article"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-if="skeleton == false">
      <v-card class="pt-5" min-height="600" flat>
        <v-container>
          <v-row>
            <v-col>
              <h2>Top Results</h2>
            </v-col>
          </v-row>
        </v-container>
        <div class="card-navbar-search">
          <v-container>
            <v-btn text class="text-capitalize" disabled>
              <b>All Results</b>
            </v-btn>
            <!-- <v-btn text class="text-capitalize"> Videos </v-btn>
          <v-btn text class="text-capitalize"> Articles </v-btn>
          <v-btn text class="text-capitalize"> Exercises </v-btn>
          <v-btn text class="text-capitalize"> Programs </v-btn> -->
          </v-container>
        </div>
        <v-container>
          <!-- <v-row>
            <v-col lg="10" align="right" justify="right" class="mt-2">
              <h4>Sort By</h4>
            </v-col>
            <v-col lg="2">
              <v-select
                :items="items"
                color=" #F39C12"
                solo
                flat
                background-color="#F5F5F5"
              ></v-select>
            </v-col>
          </v-row> -->
          <template v-if="text.length == 0">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card flat width="100%">
                    <h3 style="text-align: center">Type To Search Course</h3>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-if="contents.length == 0 && text.length != 0">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card flat width="100%">
                    <h3 style="text-align: center">No Record Found</h3>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <span v-for="(content, i) in contents" :key="i">
            <v-row class="mt-8">
              <v-col lg="12">
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col cols="12" class="mb-5">
                        <v-card flat class="search-bar-row">
                          <v-row>
                            <v-col lg="2" sm="5" cols="12">
                              <img
                                src="@/assets/nextgradeimages/searchenginepage2/nextgradelogo-indicating-course.webp"
                                width="90%"
                                class="pt-0 ms-sm-10 mt-sm-4 ms-5"
                              />
                            </v-col>
                            <v-col lg="10" sm="7" cols="12">
                              <v-card-title
                                class="text-h5 font-weight-medium"
                                @click="
                                  $router.push(`/course/${content.course_id}`),
                                    $emit('handle-search-comp')
                                "
                                >
                                
                                {{
                                  content.course_name
                                    ? content.course_name + " | "
                                    : ""
                                }}
                                {{
                                  content.cat_name
                                    ? content.cat_name + " | "
                                    : ""
                                }}
                                {{
                                  content.grade_name
                                    ? content.grade_name + " | "
                                    : ""
                                }}
                                Next Grade
                              </v-card-title>
                              <v-card-subtitle
                                class="subtitle-1 font-weight-medium pt-5"
                              >
                                {{ content.course_subtitle }}
                              </v-card-subtitle>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </span>

          <v-row v-if="total_pages > 0 && contents.length !=0">
            <v-col>
              <v-pagination
                v-model="page"
                :length="total_pages"
                :total-visible="7"
                color="#F39C12"
                circle
                outlined
                @input="searchContent"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
    <!-- this condition will remove footer from following components -->
    <div class="desktop">
      <div
        v-if="
          this.$route.name == 'logincomp' 
          ||this.$route.name == 'loginStudentcomp' 
          ||this.$route.name == 'loginTeachercomp'||
          this.$route.name == 'signUpcomp' ||
          this.$route.name == 'TeacherForgotPassword' ||
          this.$route.name == 'TeacherChangePasswordComp' ||
          this.$route.name == 'signUpStudentComp' ||
          this.$route.name == 'signUpTeacherComp' ||
          this.$route.name == 'ForgotPassword' ||
          this.$route.name == 'ConfirmPinComp' ||
          this.$route.name == 'ChangePasswordComp' ||
          this.$route.name == 'StudentMessage' ||
          this.$route.name == 'teacherMessage'
        "
      >
        <FooterComp
          
          @handle-search-comp-off="showSearchOff"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/search.css";
import { HTTP } from "@/common/common-http";
import FooterComp from "@/components/FooterComp.vue";
export default {
  name: "seacrchComp",
  components: {
    FooterComp,
  },
  data() {
    return {
      page: 1,
      total_pages: 0,
      per_page: 10,
      items: ["Relevance", "By Name", "By time"],
      text: "",
      contents: [],
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      skeleton: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.searchContent(this.page);
      },
      deep: true,
    },
    text(val) {
      console.log(val, "check");
      let trimed = val;
      if (trimed.trim()) {
        this.page = 1;
        this.total_pages = 1;
        this.searchContent();
      } else {
        this.contents = [];
        this.page = 1;
        this.total_pages = 1;
        this.searchContent();
      }
    },
  },
  methods: {
    async searchContent() {
      this.skeleton = true;
      await HTTP.post(
        `search/content?page=${this.page}&per_page=${this.per_page}`,
        {
          text: this.text,
        }
      )
        .then((response) => {
          if (response.data.status === "Success") {
            let data = response.data;
            this.contents = data.data;
            this.total_pages = data.meta.last_page;
            this.skeleton = false;
          }
        })
        .catch((err) => {
          this.skeleton = false;
          this.contents = [];
          this.page = 1;
          this.total_pages = 0;
          // this.$swal("Error!", `${err.response.data.message}`, "error");
          console.log(err.response.data.message, "errors");
        })
        .finally(() => {
          this.skeleton = false;
        });
    },
    showSearchOff() {
      this.$emit('handle-search-comp-off')
    },
  },
};
</script>
<style scoped>
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #f39c12 !important;
}
.desktop {
  display: block;
}
.tablate {
  display: none;
}
@media (max-width: 960px) {
  .desktop {
    display: none;
  }
  .tablate {
    display: none;
  }
}
.mdi-close::before {
    font-weight: 900;
}
</style>
//
<style scoped lang="scss">
// ::v-deep .v-application--wrap {
//   min-height: fit-content;
// }

//
</style>
