<template>
  <v-app style="background: #f5f5f5">
    <div class="main">
      <div v-if="search">
        <SearchComp @handle-search-comp="showSearch" @handle-search-comp-off="showSearchOff" />
      </div>
      <div v-if="!search">
        <!-- <NavbarComp v-if="this.$store.state.student.user_client_id || this.$store.state.student.cust_client_id" @handle-show="showCourses" @handle-search="showSearch" @handle-show-off="showCourseOff" /> -->
        <NavbarComp  @handle-show="showCourses" @handle-search="showSearch" @handle-show-off="showCourseOff" />
      </div>
      <div style="min-height: 400px;" v-if="!search">
        <!-- <BodyComp v-if="this.$store.state.student.user_client_id || this.$store.state.student.cust_client_id" :courseExpend="courseShow" @handle-show-off="showCourseOff" /> -->
        <BodyComp :courseExpend="courseShow" @handle-show-off="showCourseOff" />
      </div>
<!-- this condition will remove footer from following components -->
   <div class="desktop">
    <div v-if="
      this.$route.name == 'logincomp' 
      ||this.$route.name == 'loginStudentcomp' 
      ||this.$route.name == 'loginTeachercomp'
      ||this.$route.name == 'TeacherForgotPassword' 
      ||this.$route.name == 'TeacherChangePasswordComp' 
      || this.$route.name == 'signUpcomp' 
      || this.$route.name == 'signUpStudentComp' 
      || this.$route.name == 'signUpTeacherComp' 
      || this.$route.name =='ForgotPassword'
      || this.$route.name =='ConfirmPinComp'
      || this.$route.name =='ChangePasswordComp'
      || this.$route.name =='StudentMessage'
      || this.$route.name =='teacherMessage'
      ">
      </div>
      <div v-else>
        <FooterComp  @handle-show-off="showCourseOff" @handle-search-comp-off="showSearchOff" />
      </div>
   </div>

      <div class="tablate">
        <FooterComp  @handle-show-off="showCourseOff" @handle-search-comp-off="showSearchOff" />
      </div>
    </div>
  </v-app>
</template>

<script>
import NavbarComp from "@/components/NavbarComp.vue";
import BodyComp from "@/components/Body.vue";
import FooterComp from "@/components/FooterComp.vue";
import SearchComp from "@/components/SearchMeterial.vue";
import { HTTP } from "@/common/common-http";
import { HTTP_TC } from "@/common/common-http-teacher";

// import CourseMaterial from "../components/CourseMaterial.vue";
export default {
  name: "MainComp",
  components: {
    NavbarComp,
    BodyComp,
    FooterComp,
    SearchComp,
  },
  data: () => ({
    drawer: false,
    courseShow: false,
    search: false,
  }),
  methods: {
    showCourses() {
      this.courseShow = !this.courseShow;
    },
    showSearch() {
      this.search = !this.search;
      if (this.courseShow == true) {
        this.courseShow = false;
      }
    },
    showSearchOff() {
      this.search = false;
      if (this.courseShow == true) {
        this.courseShow = false;
      }
    },
    showCourseOff() {
      // console.log('sss')
      if(this.courseShow == true){
        this.courseShow = false;
      }
    },
    // fetching courses categories from here
    async fetchCourses() {
      await HTTP.get(`courses`)
        .then((response) => {
          if (response.data.status === "Success") {
            let data = response.data.data;
            // console.log("data", data);
            // sending courses categories to vuex store
            this.$store.commit({
              type: "Add_Courses_Categories",
              courses_categories: data,
            });
          }
        })
        .catch((err) => {
          // this.$swal("Error!", `${err.response.data.message}`, "error");
          console.log(err.response.data.message, "errors");
        })
        .finally(() => {});
    },
    authUser() {
      if(localStorage.getItem('studentaccesstoken')){
        HTTP.get(`auth/user`)
        .then((response) => {
          if (response.data.status === "Success") {
          console.log('ss',response.data.data)

            this.$store.commit({
              type: "Add_User",
              student: response.data.data,
            });
            this.$store.state.student_img = response.data.data.cust_profile_img
            localStorage.setItem("student_img",response.data.data.cust_profile_img)
          }
        })
        .catch((err) => {
          console.log(err.response.data.message, "errors");
          if (err.response.data.message == "Unauthenticated.") {
            localStorage.removeItem("studentaccesstoken");
            localStorage.removeItem("std_name");
            localStorage.removeItem("std_id");
            this.$router.push("/login");
          }
        });
      }else{
        console.log('sss')
        HTTP_TC.get(`auth/user`)
        .then((response) => {
          if (response.data.status === "Success") {
          console.log('teacher',response.data.data)

            this.$store.commit({
              type: "Add_User",
              student: response.data.data.user,
            });
            this.$store.state.student_img = response.data.data.user.user_profile_img
            localStorage.setItem("student_img",response.data.data.user.user_profile_img)
          }
        })
        .catch((err) => {
          console.log(err.response.data.message, "errors");
          if (err.response.data.message == "Unauthenticated.") {
            localStorage.removeItem("teacheraccesstoken");
            localStorage.removeItem("std_name");
            localStorage.removeItem("std_id");
            this.$router.push("/login");
          }
        });
      }
    },
    async fetchGrades() {
      await HTTP.get(`grades`)
        .then((response) => {
          if (response.data.status === "Success") {
            let data = response.data.data;
            // console.log("data", data);
            // sending courses categories to vuex store
            this.$store.commit({
              type: "Add_Grades",
              grades: data,
            });
          }
        })
        .catch((err) => {
          // this.$swal("Error!", `${err.response.data.message}`, "error");
          console.log(err.response.data.message, "errors");
        })
        .finally(() => {});
    },
  },
  created() {
    this.fetchCourses();
    this.fetchGrades();
    if (localStorage.getItem("studentaccesstoken") || localStorage.getItem("teacheraccesstoken")) {
      this.authUser()
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto:wght@500&display=swap');
.desktop{
    display: block;
  }
  .tablate{
    display: none;
  }
@media (max-width:960px){
  .desktop{
    display: none;
  }
  .tablate{
    display: block;
  }
}
</style>
