<template>
  <div style="background:#FFFF; " width="100%">
    <div class="ie-panel">
      <a href="https://windows.microsoft.com/en-US/internet-explorer/">
        <img
          src="images/warning_bar_0000_us.jpg"
          height="42"
          width="820"
          alt="You are using an outdated browser. For a faster, safer browsing experience, upgrade for free today."
      /></a>
    </div>
    <div class="preloader">
      <div class="preloader-body">
        <div class="cssload-bell">
          <div class="cssload-circle">
            <div class="cssload-inner"></div>
          </div>
          <div class="cssload-circle">
            <div class="cssload-inner"></div>
          </div>
          <div class="cssload-circle">
            <div class="cssload-inner"></div>
          </div>
          <div class="cssload-circle">
            <div class="cssload-inner"></div>
          </div>
          <div class="cssload-circle">
            <div class="cssload-inner"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <!-- Page Header-->
      <header class="section page-header">
        <!-- RD Navbar-->
        <div class="rd-navbar-wrap">
          <nav
            class="rd-navbar rd-navbar-classic"
            data-layout="rd-navbar-fixed"
            data-sm-layout="rd-navbar-fixed"
            data-md-layout="rd-navbar-fixed"
            data-md-device-layout="rd-navbar-fixed"
            data-lg-layout="rd-navbar-static"
            data-lg-device-layout="rd-navbar-fixed"
            data-xl-layout="rd-navbar-static"
            data-xl-device-layout="rd-navbar-static"
            data-xxl-layout="rd-navbar-static"
            data-xxl-device-layout="rd-navbar-static"
            data-lg-stick-up-offset="100px"
            data-xl-stick-up-offset="100px"
            data-xxl-stick-up-offset="100px"
            data-lg-stick-up="true"
            data-xl-stick-up="true"
            data-xxl-stick-up="true"
          >
            <div class="rd-navbar-main-outer">
              <div class="rd-navbar-main">
                <!-- RD Navbar Panel-->
                <div class="rd-navbar-panel">
                  <!-- RD Navbar Toggle-->
                  <button
                    class="rd-navbar-toggle"
                    data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                  >
                    <span></span>
                  </button>
                  <!-- RD Navbar Brand-->
                 
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <section class="breadcrumbs-custom">
        <div class="parallax-container" data-parallax-img="images/breadcrumbs-bg.jpg">
          <div class="breadcrumbs-custom-body parallax-content">
            <div class="container text-center">
              <h2 class="breadcrumbs-custom-title">Global Privacy Policy</h2>
            </div>
          </div>
        </div>
        <!-- <div class="breadcrumbs-custom-footer">
            <div class="container">
                <ul class="breadcrumbs-custom-path">
                    <li><a href="index.html">Home</a></li>
                    <li class="active">Privacy Policy</li>
                </ul>
            </div>
        </div> -->
      </section>

      <!-- Privacy Policy-->
      <section class="section section-xxl bg-default text-md-left">
        <v-container>
          <v-row >
            <v-col cols="12" lg="12">
              <!-- Terms list-->
              <dl class="list-terms list-terms-1 ul-m">
                <dt class="heading-4">Introduction</dt>
                <dd>
                  This Global Privacy Policy (“Privacy Policy”) describes how Next Grade,
                  a product of Z-Square Technology LLC. will gather, use, and maintain
                  your Personal Information on the Next Grade Platform. It will also
                  explain your legal rights with respect to that information.
                </dd>
                <dd>
                  By using the Next Grade Platform, you confirm that you have read and
                  understand this Privacy Policy, and our Global Terms of Service
                  (together referred to herein as the “Agreement”). The Agreement governs
                  the use of the Next Grade Platform. Next Grade will collect, use, and
                  maintain information consistent with the Agreement.
                </dd>
                <dd>
                  If you are a California resident or data subject in Europe, please see
                  the “Additional Disclosures for California Residents” and “Additional
                  Disclosures for Data Subjects in the European Economic Area (EEA) and
                  Switzerland” sections, respectively. If you have any questions or wish
                  to exercise your rights and choices, please contact us as set out in the
                  “Contact Us” section below.
                </dd>
                <dt class="heading-4">General Terms</dt>
                <dt class="heading-6">In this Privacy Policy:</dt>

                <dd>
                  <ul>
                    <li>
                      Next Grade, Inc. may be referred to as
                      <strong>"Next Grade," “we,” “our,”</strong>
                      or
                      <strong>“us.”</strong>
                    </li>
                    <li>
                       We call a user of the Next Grade Platform
                      <strong>“User,” “Users,”</strong> “you,” “your,” or “Client” and
                      “Provider,” as appropriate.
                    </li>
                    <li>
                       The community platform that we offer at our website (
                      <a href="http://nxgrade.com/">www.nxgrade.com</a> and other local
                      variants) is referred to as the “Site(s).”
                    </li>
                    <li>
                       The mobile applications (whether on iOS or Android) are referred
                      to as the “Apps.”
                    </li>
                    <li>
                       Next Grade’s Sites, Apps and related services, information and
                      communications are collectively referred to as the
                      <strong>“Next Grade Platform.”</strong>
                    </li>
                    <li>
                       <strong>“Terms of Service”</strong> refers to our Global Terms of
                      Service, which can be found here.
                    </li>
                    <li>
                      This Privacy Policy is incorporated into, and considered a part
                      of, the Terms of Service.
                    </li>
                  </ul>
                </dd>

                <dt class="heading-4">Information Collection</dt>
                <dt class="heading-6">Information you provide to Next Grade</dt>
                <dd>
                  Next Grade collects certain personally identifiable information about
                  you, including information that is reasonably capable of being
                  associated with you (“Information”). Examples of Information that Next
                  Grade collects include but are not limited to:
                </dd>

                <dd>
                  <strong>Contact Information. </strong>This may include your first and
                  last name, postal address, telephone number, and email address.
                </dd>
                <dd>
                  <strong>Billing Data, </strong>including your payment instrument number
                  (such as a credit or debit card number), expiration date, and security
                  code as necessary to process your payments.
                </dd>
                <dd>
                  <strong>Identity Information. </strong>If you are a Provider, we may
                  collect Personal Information, such as your date of birth and address,
                  national identification number if applicable, together with the result
                  of basic criminal record checks as provided by you, or by our Third
                  Party Agents (as defined below), as applicable and to the extent
                  permitted by law in your jurisdiction, and to validate your identity.
                </dd>
                <dd>
                  <strong>Financial Information. </strong>To help Providers set up a
                  payment account and help Clients make payments to Providers and pay fees
                  to Next Grade, we may collect financial information, including credit
                  card number, bank routing numbers, tax information, taxpayer
                  identification number, and other payment information, as applicable. We
                  use Financial Information in order to operate the Next Grade Platform
                  and to ensure that Providers are paid by Clients. We do this as
                  necessary for our legitimate interests in providing our platform and
                  services and to fulfill our contracts with Users. To keep your financial
                  data secure, we have contracted with a third party to maintain and
                  process your payment card information.
                </dd>
                <dd>
                  <strong>Promotional Information. </strong>Certain offerings of the Next
                  Grade Platform, such as newsletters, surveys, contests, and the like are
                  optional and so you are not required to enter them or to give us your
                  data in connection with them. Where you do consent to take advantage of
                  Next Grade Platform offerings, we will use your data to (as applicable)
                  send you newsletters and other communications that are tailored based on
                  information we have about you, or to operate and manage the survey,
                  contest or similar offering in connection with our legitimate interest
                  in promoting our business and the Next Grade Platform. To opt-out of
                  receiving marketing communications from us, please see the
                  Choice/Opt-Out section below.
                </dd>
                <dd>
                  <strong>Content Information. </strong>You also may choose to send Next
                  Grade Personal Information in an email or chat message containing
                  inquiries about the Next Grade Platform and we use this Information in
                  order to help us respond to your inquiry. We also collect content within
                  any messages you exchange with other Users through the Service (such as
                  through our chat functionality), and we will never use or share such
                  information for marketing purposes.
                </dd>
                <dd>
                  We require that you furnish your Contact Information and Financial
                  Information when you register an account with us in order to provide
                  services through the Next Grade Platform. For example, if you are a
                  Client, we collect your first and last name, email address, and your zip
                  or postal code in order to create and administer your Next Grade
                  account. We also collect additional information in order to facilitate
                  your booking request, such as information about the Products or Service
                  you are seeking, the time, date and location of the Products or Service,
                  and Financial Information. If you are a Provider, we collect your first
                  and last name, email address, mobile phone number and zip or postal code
                  in order to create and administer your Next Grade account and facilitate
                  communications between you and your Clients through the Platform. We
                  also collect information about your products and services, rates, and
                  other skills, as well as Identity Information and Financial Information.
                </dd>
                <dd>
                  <strong>Third Party Information. </strong>If you registered through a
                  co-branded version of our Next Grade Platform through one of our, they
                  may provide us with basic information such as your name, address, email,
                  and purchase history related to your services. We may receive additional
                  information about you, such as demographic data, Financial Information,
                  or fraud detection information, from Third Party Agents (as defined
                  below) and combine it with other information that we have about you, to
                  the extent permitted by law, in order to comply with our legal
                  obligations and for the legitimate interest in improving the Next Grade
                  Platform. Next Grade may work with Third Party Agents to perform
                  identity checks, criminal background checks, and right to work checks on
                  Providers, if applicable and permitted by local law, in order to advance
                  our legitimate interests in ensuring the safety of our Users and
                  maintaining the integrity of the Next Grade Platform.
                </dd>

                <dt class="heading-4">Information Next Grade Collects Automatically</dt>

                <dd>
                  We automatically collect certain information when you use the Service.
                  The categories of information we automatically collect and have
                  collected, including in the last 12 months, are as follows:
                </dd>
                <dd>
                  <strong>Service Use Data, </strong>including data about features you
                  use, pages you visit, emails and advertisements you view, portions of
                  the Next Grade Platform that you view and interact with, the time of day
                  you browse, and your referring and exiting pages.
                </dd>
                <dd>
                  <strong>Device Data, </strong>including data about the type of device or
                  browser you use, your device’s operating system, your internet service
                  provider, your device’s regional and language settings, and device
                  identifiers such as IP address and Ad Id. When you visit and interact
                  with the Next Grade Platform, Next Grade may collect certain information
                  automatically through cookies or other technologies, including, but not
                  limited to, the type of computer or mobile device you use, your mobile
                  device’s unique device ID, the IP address of your computer or mobile
                  device, your operating system, the type(s) of internet browser(s) you
                  use, and information about the way you use the Next Grade Platform
                  (“<strong>Device Information”</strong>). We may use Device Information
                  to monitor the geographic regions from which Users navigate the Next
                  Grade Platform, and for security and fraud prevention purposes. Use of
                  any IP-masking technologies or similar technologies (like the TOR
                  network) may render portions of the Next Grade Platform unavailable and
                  are forbidden on the Next Grade Platform.
                </dd>
                <dd>
                  <strong>Location Data, </strong>including imprecise location data (such
                  as location derived from an IP address or data that indicates a city or
                  postal code level), and, with your consent, precise location data (such
                  as latitude/longitude data). When you visit the Next Grade Platform via
                  a native mobile application, we use, with your consent when required
                  under applicable law, GPS technology (or other similar technology) to
                  determine your current location in order to determine the city you are
                  located in and display a relevant location map. We will not share your
                  current location obtained in this manner with other Users.
                </dd>
                <dd>
                  We also use various Tracking Technologies (<strong
                    >“Tracking Technologies”</strong
                  >) to automatically collect information when you use the Next Grade
                  Platform, including the following:
                </dd>
                <dd>
                  <strong>Cookies. </strong>When you visit websites our Sites, your
                  browser may automatically transmit information to these websites
                  throughout the visit. In a similar way, when you use our mobile
                  applications, we will access and use mobile device IDs to recognize your
                  device. We use “cookies” and equivalent technologies to collect
                  information through our Site and Apps. Cookies are small data files
                  stored on your device that act as a unique tag to identify your browser.
                </dd>
                <dd>
                  Persistent cookies help with personalizing your experience, remembering
                  your preferences, and supporting security features. Additionally,
                  persistent cookies allow us to bring you advertising both on and off the
                  Platform. Persistent cookies may remain on your device for extended
                  periods of time, and generally may be controlled through your browser
                  settings. We utilize persistent cookies that only Next Grade can read
                  and use, and access mobile device IDs to:

                  <ul>
                    <li>
                       save your login information for future logins to the Next Grade
                      Platform;
                    </li>
                    <li> assist in processing items during checkout;</li>
                    <li> hold session information; and</li>
                    <li> track user preferences.</li>
                  </ul>
                </dd>
                <dd>
                  Session cookies make it easier for you to navigate our website and
                  expire when you close your browser. We utilize session ID cookies and
                  similar technologies to:

                  <ul>
                    <li> enable certain features of the Next Grade Platform;</li>
                    <li>
                       better understand how you interact with the Sites and the Next
                      Grade Platform;
                    </li>
                    <li>
                       monitor usage by our Users and web traffic routing on the Next
                      Grade Platform;
                    </li>
                    <li>
                      track the number of entries in Next Grade promotions, sweepstakes
                      and contests; and identify visited areas of the Next Grade Platform.
                    </li>
                  </ul>
                </dd>
                <dd>
                  Unlike persistent cookies, session cookies are deleted from your
                  computer when you log off from the Next Grade Platform and then close
                  your browser.
                </dd>
                <dd>
                  Exhibit A sets out the different categories of cookies that the Next
                  Grade Platform uses and why we use them.
                </dd>
                <dd>
                  We may work with third party advertisers who may also place or read
                  persistent cookies on your browser, and we may use Flash cookies (or
                  local shared objects) to store your preferences or display content based
                  upon what you view on the Sites to personalize your visit.
                </dd>
                <dd>
                  You can instruct your browser, by changing its options, to stop
                  accepting cookies or to prompt you before accepting a cookie from the
                  websites you visit. If you do not accept cookies, however, you will not
                  be able to use all portions or all functionalities of the Next Grade
                  Platform.
                </dd>
                <dd>
                  To change your cookie settings for cookies on the Sites, please click on
                  the following addresses based on the browser you use:
                  <a
                    href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies/"
                    target="_blank"
                  >
                    Internet Explorer</a
                  >,
                  <a href="https://support.apple.com/en-us/HT201265" target="_blank"
                    >Safari</a
                  >,
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=en&hlrm=en&safe=on/"
                    target="_blank"
                    >Chrome</a
                  >,
                  <a
                    href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                    target="_blank"
                    >Firefox</a
                  >, and
                  <a
                    href="https://help.opera.com/en/latest/web-preferences/"
                    target="_blank"
                    >Opera.</a
                  >
                </dd>

                <dd>
                  <strong>Pixels. </strong>We and our Third Party Agents may also use
                  "pixel tags," "web beacons," "clear GIFs," or similar means in
                  connection with the Next Grade Platform and HTML-formatted email
                  messages to, among other things, track the actions of Users and email
                  recipients, determine the success of marketing campaigns, and compile
                  statistics about Site usage and response rates.
                </dd>

                <dt class="heading-4">Next Grade's Use of Information</dt>

                <dd>
                  We collect and use information for business and commercial purposes in
                  accordance with the practice described in this Privacy Policy. Our
                  business purposes for collecting and using information include:

                  <ul>
                    <li>
                      To operate and make available the Next Grade Platform. We use your
                      data to connect you with other Users to enable the posting of,
                      selection for, completion of, and payment for Products or Services,
                      in order to fulfill our contracts with Users;
                    </li>
                    <li>
                       For billing and fraud prevention, on the basis of our legitimate
                      interests in ensuring a safe and secure environment in which Clients
                      and Providers can meet and conduct business, and in order to comply
                      with our legal obligations;
                    </li>
                    <li>
                       To conduct identification, criminal background, and right to work
                      checks, if applicable, and to the extent permitted by local law, on
                      Users, in order to advance our legitimate interests as well as for
                      the substantial public interest of ensuring the safety of our Users
                      both online and offline, preventing or detecting unlawful acts,
                      protecting the public against dishonesty, and maintaining the
                      integrity of the Next Grade Platform given that Providers often
                      interact directly with Clients and may enter their homes;
                    </li>
                    <li>
                       To analyze Next Grade Platform usage as necessary for our
                      legitimate interest in improving the Next Grade Platform to grow our
                      business;
                    </li>
                    <li>
                       To contact you and deliver (via email, SMS, push notifications, or
                      otherwise) transactional information, administrative notices,
                      marketing notifications, offers and communications relevant to your
                      use of the Next Grade Platform, with your consent when required
                      under applicable law, as necessary for our legitimate interests in
                      proper communication and engagement with our Users and in promoting
                      our business;
                    </li>
                    <li>
                      To provide you with customer support in order to fulfill our
                      contracts with Users;
                    </li>
                    <li>
                      For internal market research for our legitimate interest in
                      improving the Next Grade Platform to grow our business;
                    </li>
                    <li>
                      For troubleshooting problems for our legitimate interests in
                      ensuring a safe and secure environment in which Users can meet;
                    </li>
                    <li>
                      To assist Users in the resolution of complaints and disputes
                      between them, as necessary for our legitimate interests in
                      facilitating good relations among Users;
                    </li>
                    <li>
                     To enforce our Terms of Service and our legitimate interests in
                      ensuring our Agreement is complied with; and
                    </li>
                    <li> As otherwise set forth in the Agreement.</li>
                  </ul>
                </dd>

                <dd>
                  <strong>Interest-Based Advertising. </strong>Ads are a standard part of
                  user experience on the Internet, and Next Grade believes that targeted
                  advertising enhances this experience. Next Grade and affiliate third
                  parties may use cookies and other technologies to place ads where they
                  believe interested Users will see them. In addition to banner ads, Next
                  Grade may advertise products, companies and events that we think might
                  interest you through the email address you provide. We may incorporate
                  tracking Technologies into our own Service (including our website and
                  emails) as well as into our ads displayed on other websites and
                  services. Some of these Tracking Technologies may track your activities
                  across time and services for purposes of associating the different
                  devices you use, and delivering relevant ads and/or other content to you
                  (<strong>“Interest-Based Advertising”</strong>).
                </dd>
                <dd>
                  For more information and to understand your choices regarding third
                  party ads, please see the “Cookies and Other Technologies and
                  Interest-Based Advertising” in Exhibit A. Advertising and marketing is
                  carried out as necessary for our legitimate interests in providing an
                  engaging and relevant experience, promoting our services, and growing
                  our business.
                </dd>
                <dd>
                  <strong>Analytics and Market Analysis. </strong>Next Grade may analyze
                  information (<strong>“Market Analysis”</strong>) as necessary for our
                  legitimate interests in providing an engaging and relevant experience,
                  and promoting and growing the Next Grade Platform.
                </dd>
                <dd>
                  Next Grade uses information to offer services to Users who express an
                  interest in these services, through a poll for example, or to Users who
                  can be presumed to have an interest based on results from our Market
                  Analysis. We do this as necessary for our legitimate interests in
                  providing an engaging and relevant experience, promoting our services,
                  and growing our business.
                </dd>
                <dd>
                  <strong>Cell Phone Numbers. </strong>Next Grade may use your cell phone
                  number to call or send recurring text messages to you, using an
                  autodialer or prerecorded voice, in order to provide you notifications
                  about Products or Services, for marketing purposes (with your consent
                  where required by law), and to administer the Next Grade Platform. If
                  you would like more information about our policy, or how to opt out,
                  please review the “Choice/Opt Out” section below or Section 9 of our
                  <a href="terms.html"> Terms of Service </a>. You may be liable for
                  standard SMS and per-minute charges by your mobile carrier. Next Grade
                  may also message you via push notifications (with your consent when
                  required under applicable law), which you can opt-out of on your mobile
                  device. Data rates may apply.
                </dd>

                <dt class="heading-4">Information Sharing</dt>
                <dd>
                  We only share the Information we collect about you as described in this
                  Privacy Policy or as described at the time of collection or sharing,
                  including as follows:
                </dd>
                <dd>
                  <strong>Third Party Agents. </strong>We share information, including
                  Identity Information, with entities that process information on our
                  behalf for our business purposes. Third Party Agents assist us with
                  services such as payment processing, data analytics, marketing and
                  advertising, website hosting, fraud prevention and detection,
                  communication services, and technical support. We contractually prohibit
                  our Third Party Agents from retaining, using, or disclosing information
                  about you for any purposes other than performing the services for us,
                  although we may permit them to use information that does not identify
                  you (including information that has been aggregated or de-identified)
                  for any purpose except as prohibited by applicable law.
                </dd>
                <dd>
                  To operate the Next Grade Platform, including processing your
                  transactions, we may share your information with our agents,
                  representatives, vendors and service providers (<strong
                    >“Third Party Agents”</strong
                  >) so they can provide us with support services as follows:

                  <ul>
                    <li> Email origination;</li>
                    <li>
                       Identity checks (currently carried out by our providers IDology
                      (U.S), Sterling (Canada) and Jumio (U.S., France, Germany and
                      Spain)), and criminal background checks (currently carried out by
                      our provider Sterling in U.S. and Canada), to the extent permitted
                      by applicable law;
                    </li>
                    <li>
                       Fraud detection (currently carried out by our providers Sift
                      Science and Emailage). Our fraud detection providers also use
                      information relating to you and your use of and activity on the Next
                      Grade Platform to provide fraud detection services to their other
                      clients;
                    </li>
                    <li> Receipt, invoice, or support services;</li>
                    <li> Customer relationship management services;</li>
                    <li>
                       Financial transaction fulfillment (in which we are currently
                      supported by Stripe, who processes your information in accordance
                      with its own
                      <a href="https://stripe.com/privacy" target="_blank">
                        privacy policy</a
                      >) and related chargeback and collection services; and
                    </li>
                    <li>To otherwise help us provide the Next Grade Platform.</li>
                  </ul>
                </dd>
                <dd>
                  <strong>Partners. </strong>Some Next Grade content is "sponsored by" or
                  "presented by" other companies. If you connect to the Next Grade
                  Platform through a co-branded version of our Next Grade Platform or
                  otherwise participate in one of our partner programs, we may share
                  information about your use of the Next Grade Platform with that Partner
                  in order to offer the integrated platform and to evaluate the partner
                  program. We may also share your information with our Partners in order
                  to receive additional information about you, or in order to create
                  offers that may be of interest to you. Please check such Partner’s
                  privacy policy before revealing information about yourself. If you don't
                  want these Partners to have your Personal Information, you can choose to
                  not participate.
                </dd>
                <dd>
                  <strong>Promotions. </strong>When you voluntarily enter a sweepstakes,
                  contest, or other promotion, we share information as set out in the
                  official rules that govern the promotion as well as for administrative
                  purposes and as required by law (e.g., on a winners’ list). By entering
                  a promotion, you agree to the official rules that govern that promotion,
                  and may, except where prohibited by applicable law, allow the sponsor
                  and/or other entities to use your name, voice, and/or likeness in
                  advertising or marketing materials. We may occasionally contact you, if
                  you want us to, with information about special events, activities,
                  promotions, contects, submission opportunities, and programs. You always
                  have the ability, in your Account, to ask Next Grade not to contact you
                  with this type of information. Please see the Your Rights and Choices
                  section for more information.
                </dd>
                <dd>
                  <strong>Other Users. </strong>Next Grade facilitates contact between
                  Clients and Providers and reserves the right to share a Provider’s
                  contact information (e.g. name, phone number, email, or postal address)
                  with a Client and vice versa, or with their legal or other
                  representative, in order to facilitate: (1) the resolution of a dispute
                  related to or arising from an interaction between two or more Users of
                  the Next Grade Platform; or (2) the performance of a Products or
                  Service. This exchange of information is a mandatory part of the Next
                  Grade Platform.
                </dd>
                <dd>
                  <strong>Legal Obligations. </strong>Next Grade and our Third Party
                  Agents may disclose information or User Generated Content, including
                  location data or communication data, to a third party if required or
                  permitted to do so by law or pursuant to a court order, warrant or
                  subpoena. Next Grade reserves the right to disclose Personal Information
                  from both private and public areas of the Site(s) and the Next Grade
                  Platform in the absence of a court order, warrant or subpoena, to the
                  extent permitted by applicable law, if we are given reason to believe,
                  in our sole discretion, that someone is causing injury to or interfering
                  with the rights of Users, the general public, or Next Grade or its
                  partners, parents or affiliates.
                </dd>
                <dd>
                  It is our policy to provide notice to Users before producing their
                  information in response to law enforcement requests unless (i) we are
                  prohibited or otherwise constrained by law or court order from doing so,
                  (ii) we have reason to believe the User’s account has been compromised
                  such that the notice would go to the wrong person, or notice would
                  otherwise be counterproductive or would create a risk to safety, or
                  (iii) it is an emergency request and prior notice would be impractical
                  (in which case we may provide notice after the fact).
                </dd>
                <dd>
                  <strong>Merger or Acquisition. </strong>Next Grade reserves the right to
                  share information in connect with, or during negotiations of, any
                  proposed or actual merger, purchase, sale, or any other type of
                  acquisition or business combination of all or any portion of our assets,
                  or transfer of all or a portion of our business to another business.
                </dd>
                <dd>
                  <strong>Public Areas. </strong>Your profile on the Next Grade Platform
                  consists of information about you and your business, and may include
                  information such as photographs, your years in business, skills and
                  expertise, hourly pay rates, feedback/rating information, and other
                  information, including your username (<strong>“Profile”</strong>). The
                  information in your User Profile may be visible to all Users and the
                  general public. If you choose to post a Product or Service via the Next
                  Grade Platform, the contents of such listing will be viewable to
                  Providers you select on the Next Grade Platform.
                </dd>

                <dt class="heading-4">Your Rights and Choices</dt>
                <dd>
                  You may opt-out of receiving promotional communications from us and our
                  Partners, remove your information from our database, choose to not
                  receive future promotional communications related to the Next Grade
                  Platform, or cancel your account by logging on to the Site(s) and
                  clicking on the “Account” tab, or by contacting us.
                </dd>
                <dd>
                  <strong>Account Settings. </strong>During registration you choose
                  whether to receive marketing correspondence from Next Grade and its
                  affiliates and Partners. This information remains on your Profile where
                  you can, at any point, easily edit it. After logging on, click on the
                  “Account” tab, then select “Notifications” to make your preferred
                  selections.
                </dd>
                <dd>
                  <strong>Push Notifications. </strong>You have the option to receive
                  updates and relevant offers via push notifications in your app. These
                  notifications can be configured in the settings of your mobile device.
                </dd>
                <dd>
                  <strong>Corrections to Profile. </strong>You have the right to access,
                  update, and correct inaccuracies in your Next Grade Profile at any time
                  by logging in and clicking on the “Account” tab. There, you can view,
                  update, and correct your Account information. Our databases
                  automatically update any information you edit in your Profile under the
                  “Account” tab. So that we may protect the integrity of the Next Grade
                  Platform, there are certain pieces of information, such as your age,
                  that you cannot alter yourself. For example, since children under the
                  age of majority in their jurisdiction of residence are not allowed to
                  register as Users, we need to take reasonable measures to preserve the
                  accuracy of our Users' ages. You may contact us at
                  <a href="mailto:privacy@nxgrade.com">privacy@Next Grade.com</a> if
                  there is a need to make a correction to such data. Please see the
                  Children’s Privacy section for more details.
                </dd>
                <dd>
                  <strong>Promotional Communications. </strong>You can opt out of
                  receiving promotional communications from Next Grade sent via email by
                  clicking on the unsubscribe link in any message. You can opt out of
                  receiving promotional communications from Next Grade sent via text
                  message at any time by following the instructions provided in those
                  messages to text the word "STOP". Please note that your opt-out is
                  limited to the phone number used and will not affect subsequent
                  subscriptions. If you opt-out of only certain communications, other
                  subscription communications may continue. Even if you opt-out of
                  receiving promotional communications, Next Grade may continue to send
                  you non-promotional communications, such as those about your account,
                  Products or Services, transactions, servicing, or Next Grade’s ongoing
                  business relationship with you. If you receive unsolicited email from a
                  Next Grade domain, please let us know, <a href="help.html">here</a>.
                </dd>
                <dd>
                  <strong>Cookies and Pixels. </strong>Most browsers accept cookies by
                  default. You can instruct your browser, by changing its settings, to
                  decline or delete cookies. If you use multiple browsers on your device,
                  you will need to instruct each browser separately. Your ability to limit
                  cookies is subject to your browser settings and limitations.
                </dd>
                <dd>
                  <strong>Do Not Track. </strong>Your browser settings may allow you to
                  automatically transmit a “Do Not Track” signal to online services you
                  visit. Note, however, there is no industry consensus as to what site and
                  app operations should do with regard to these signals. Accordingly,
                  unless and until the law is interpreted to require us to do so, we do
                  not monitor or take action with respect to “Do Not Track” signals. For
                  more information on “Do Not Track,” visit
                  <a href="https://www.allaboutdnt.com" target="_blank">allaboutdnt.com</a
                  >.
                </dd>
                <dd>
                  <strong>App and Location Technologies. </strong>You can stop all
                  collection of information via an app by uninstalling the app. You can
                  also reset your device Ad Id at any time through your device settings,
                  which is designed to allow you to limit the use of information collected
                  about you. If you do not want us to use your location anymore for the
                  purposes set forth above, you should turn off the location services for
                  the mobile application located in your device’s account settings, your
                  mobile phone settings, and/or within the mobile application.
                </dd>
                <dd>
                  If you are a California Resident, please see the Additional Disclosures
                  for California Residents section below.
                </dd>

                <dt class="heading-4">Next Grade's Information Retention Policy</dt>
                <dd>
                  We retain personal data for as long as you are a User in order to meet
                  our contractual obligations to you, and for such longer period as may be
                  in our legitimate interests and to comply with our legal obligations
                  (see Exhibit B for exemplar document retention periods). We may also
                  retain data from which you cannot directly be identified, for example
                  where stored against a randomly-generated identifier so we know the data
                  relates to a single User, but we cannot tell who that User is. We use
                  this kind of data for research purposes and to help us develop and
                  improve our services, and we take appropriate measures to ensure you
                  cannot be re-identified from this data.
                </dd>

                <dt class="heading-4">
                  . Next Grade’s Security of Collected Information
                </dt>
                <dd>
                  Your Next Grade account is password-protected so that only you and
                  authorized Next Grade staff have access to your account information. In
                  order to maintain this protection, do not give your password to anyone.
                  Also, if you share a computer, you should sign out of your Next Grade
                  account and close the browser window before someone else logs on. This
                  will help protect your information entered on public terminals from
                  disclosure to third parties.
                </dd>
                <dd>
                  Next Grade implements and maintains reasonable administrative, physical,
                  and technical security safeguards to help protect information about you
                  from loss, theft, misuse and unauthorized access, disclosure, alteration
                  and destruction. Next Grade has staff dedicated to maintaining this
                  Privacy Policy and other privacy initiatives, periodically reviewing
                  security, and making sure that every Next Grade employee is aware of our
                  security practices. Nevertheless, transmission via the internet is not
                  completely secure and we cannot guarantee the security of information
                  about you.
                </dd>

                <dt class="heading-4">Notification of Changes</dt>
                <dd>
                  Next Grade's Privacy Policy is periodically reviewed and enhanced as
                  necessary. This Privacy Policy might change as Next Grade updates and
                  expands the Next Grade Platform. Next Grade will endeavor to notify you
                  of any material changes by email. Next Grade also encourages you to
                  review this Privacy Policy periodically.
                </dd>

                <dt class="heading-4">Children’s Privacy</dt>
                <dd>
                  This service is intended for a general audience, and is not directed at
                  children under 13 years of age. In certain jurisdictions, this minimum
                  age may be higher. Please check the Jurisdiction-specific Provisions
                  below for more information.
                </dd>
                <dd>
                  We do not knowingly gather personal information (as defined by the U.S.
                  Children’s Privacy Protection Act, or “COPPA”) in a manner not permitted
                  by COPPA. If you are a parent or guardian and you believe that we have
                  collected information from your child in a manner not permitted by law,
                  please let us know by contacting us. We
                  will remove the data.
                </dd>
                <dd>
                  We do not knowingly collect or “sell” the Personal Information of minors
                  under 18 years old who are California residents.
                </dd>

                <dt class="heading-4">Contacting Us</dt>
                <dd>
                  If you have any questions about this Privacy Policy or the manner in
                  which we or our Third Party Agents treat your Personal Information, the
                  practices of the Site, your dealings with the Next Grade Platform, or if
                  you have technical problems, you may contact us
                  <a href="help.html">here</a>.
                </dd>
                <dd>
                  Next Grade's staff will respond to all mail or email from Users with
                  questions about privacy, including the types of Personal Information
                  stored on the Next Grade database, and requests to delete or rectify
                  such Personal Information.
                </dd>

                <dt class="heading-4">Jurisdiction-specific Provisions</dt>
                <dt class="heading-6">A. Residents of the United States of America</dt>
                <dd>
                  <strong>Information of U.S. Users. </strong>Our collection, use, and
                  retention of the Information of U.S. Users is in accordance with
                  applicable U.S. laws, as is our determination of what is deemed to be
                  “personal data and/or information”. Such collection, use, and retention
                  laws are different from those afforded to EU Users under the General
                  Data Protection Regulation of 2018 (<strong>“GDPR”</strong>).
                </dd>
                <dd>
                  Next Grade expressly disclaims any liability that may arise should any
                  other individuals obtain the information you submit to the Next Grade
                  Platform.
                </dd>
                <dd>
                  <strong>Interest-Based Advertising in the United States. </strong>For
                  more information about interest-based ads, or to opt out of having your
                  web-browsing information used for behavioral advertising purposes,
                  please visit
                  <a href="https://www.aboutads.info/choices" target="_blank"
                    >aboutads.info/choices</a
                  >.
                </dd>
                <dd>
                  <strong>Next Grade’s Security of Collected Information. </strong>While
                  Next Grade will use commercially reasonable efforts to ensure the
                  security of all Financial Information and Personal Information, we
                  expressly disclaim any liability for any unauthorized access to or use
                  of our secure servers and/or any and all Personal Information and/or
                  Financial Information stored therein, and you agree to hold Next Grade
                  harmless for any damages that may result therefrom. If you have any
                  further questions on this issue, please refer to the
                  <a href="terms.html">Terms of Service</a>.
                </dd>

                <dt class="heading-6">
                  B. Additional Disclosures for California Residents
                </dt>

                <dd>
                  These additional disclosures for California residents apply only to
                  individuals who reside in California. The California Consumer Privacy
                  Act of 2018 (<strong>“CCPA”</strong>) provides additional rights to
                  know, delete and opt out, and requires business collecting or disclosing
                  Personal Information to provide notice of rights California residents
                  have and can exercise.
                </dd>
                <dd>
                  <strong>California Notice of Collection. </strong>We have collected
                  information corresponding to the following categories of information
                  enumerated in the CCPA.

                  <ul>
                    <li>
                       Identifiers, including name, address, email address, account name,
                      Social Security Number, IP address - and an ID number assigned to
                      your account.
                    </li>
                    <li>
                       Customer records, phone number, billing address, credit or debit
                      card information, employment or education information.
                    </li>
                    <li>
                       Demographic information, such as your age or gender. This category
                      includes pieces of Personal Information that also qualify as
                      protected classification characteristics under other pre-existing
                      California or federal laws.
                    </li>
                    <li>
                       Analytics and Advertising, including purchases and engagement with
                      the Services.
                    </li>
                    <li>
                       Internet activity, including history of visiting and interacting
                      with our Service, browser type, browser language and other
                      information collected automatically.
                    </li>
                    <li>
                       Geolocation data, including location enabled services such as WiFi
                      and GPS.
                    </li>
                    <li>
                      Inferences, including information about your interests and
                      preferences.
                    </li>
                  </ul>
                </dd>
                <dd>
                  For more information on what we collect, including the sources we
                  receive information from, review the Information Collection section. We
                  collect and use these categories of Personal Information for the
                  business purposes described in the Next Grade’s Use of Information
                  section, including to provide and manage our Services.
                </dd>
                <dd>
                  Next Grade does not generally sell information as the term “sell” is
                  traditionally understood. However, to the extent “sale” under the CCPA
                  is interpreted to include advertising technology activities such as
                  those disclosed in the Next Grade’s Use of Information section as a
                  “sale,” we will comply with applicable law as to such activity.
                </dd>
                <dd>
                  Next Grade discloses the following categories of information for
                  commercial purposes:

                  <ul>
                    <li> Commercial Information;</li>
                    <li> Demographic Data;</li>
                    <li> Location Data;</li>
                    <li> Identifiers;</li>
                    <li> Inferences; and</li>
                    <li> Internet activity.</li>
                  </ul>
                </dd>

                <dd>
                  We use and partner with different types of entities to assist with our
                  daily operations and manage our Service. Please review the Information
                  Sharing section for more detail about the parties we share information
                  with.
                </dd>
                <dd>
                  <strong>Right to Know and Delete. </strong>If you are a California
                  resident, you have the right to know certain information about our data
                  practices in the preceding 12 months. In particular, you have the right
                  to request the following from us:

                  <ul>
                    <li>
                       The categories of Personal Information we have collected about
                      you;
                    </li>
                    <li>
                       The categories of sources from which the Personal Information was
                      collected;
                    </li>
                    <li>
                       The categories of Personal Information about you we disclosed for
                      a business purpose or sold;
                    </li>
                    <li>
                       The categories of third parties to whom the Personal Information
                      was disclosed for a business person or sold;
                    </li>
                    <li>
                       The business or commercial purpose for collecting or selling the
                      Personal Information; and
                    </li>
                    <li>
                       The specific pieces of Personal Information we have collected
                      about you.
                    </li>
                  </ul>
                </dd>

                <dd>
                  However, this is not an absolute right and Next Grade may have legal
                  grounds for keeping such data.
                </dd>
                <dd>
                  To exercise any of these rights, please submit a request at
                  <a href="mailto:privacy@nxgrade.com">privacy@Next Grade.com</a> . In the request, please specify which
                  right you are seeking to exercise and the scope of the request. We will
                  confirm receipt of your request within 10 days. We may require specific
                  information from you to help us verify your identity and process your
                  request. If we are unable to verify your identity, we may deny your
                  requests to know or delete.
                </dd>
                <dd>
                  <strong>Authorized Agent. </strong>You can designate an authorized agent
                  to submit requests on your behalf. However, we will require written
                  proof of the agent’s permission to do so and verify your identity
                  directly.
                </dd>
                <dd>
                  <strong>Right to Non-Discrimination. </strong>You have the right to
                  non-discriminatory treatment by us, should you exercise any of your
                  rights.
                </dd>
                <dd>
                  <strong>Shine the Light. </strong>Customers who are residents of
                  California may request info: a list of the categories of Personal
                  Information disclosed by us to third parties during the immediately
                  preceding calendar year for those third parties’ own direct marketing
                  purposes, and (ii) a list of the categories of third parties to whom we
                  disclosed such information. To exercise a request, please contact us at
                  <a href="mailto:policies@nxgrade.com">policies@Next Grade.com</a>".
                  Requests must include “California Shine the Light Request” in the first
                  line of the description and include your name, street address, city,
                  state, and ZIP code. We may require additional information from you to
                  allow us to verify your identity and are only required to respond to
                  requests once during any calendar year. Please note that Next Grade is
                  not required to respond to requests made by means other than through the
                  provided email address or mail address.
                </dd>

                <dt class="heading-6">
                  C. Additional Disclosures for Data Subjects in the European Economic
                  Area (EEA) and Switzerland.
                </dt>
                <dd>
                  <strong>Transfer Of Data. </strong>We and our affiliates and Third Party
                  Agents primarily store data about you, including Personal Information,
                  on servers located and operated within the United States. If you reside
                  or are located outside of the U.S., we may send and store your Personal
                  Information (also commonly referred to as personal data) to the U.S. in
                  order to provide and operate the Next Grade Platform. Next Grade
                  undertakes to ensure an adequate level of protection of the personal
                  data transferred outside the European Economic Area, either by us, our
                  affiliates, and/or Third Party Agents, in accordance with applicable
                  legislation, in particular via the signature of the standard contractual
                  clauses approved by the European Commission or the Privacy Shield
                  certification.
                </dd>

                <dd>
                  <strong>Your Rights. </strong>You have the following rights under
                  certain circumstances:

                  <ul>
                    <li>
                      A right of access: you have the right to obtain confirmation as to
                      whether personal data concerning you are processed or not and, if
                      processed, to obtain access to such data and a copy thereof;
                    </li>
                    <li>
                      A right to rectification: you have the right to obtain the
                      rectification of any inaccurate personal data concerning you. You
                      also have the right to have incomplete personal data completed,
                      including by means of providing a supplementary statement;
                    </li>
                    <li>
                      A right to erasure: in some cases, you have the right to obtain
                      the erasure of personal data concerning you. However, this is not an
                      absolute right and Next Grade may have legal or legitimate grounds
                      for keeping such data;
                    </li>
                    <li>
                      A right to restriction of processing: in some cases, you have the
                      right to obtain restriction of processing of your personal data;
                    </li>
                    <li>
                      A right to data portability: you have the right to receive the
                      personal data concerning you which you have provided to Next Grade,
                      in a structured, commonly used and machine-readable format, and you
                      have the right to transmit those data to another controller without
                      hindrance from Next Grade. This right only applies when the
                      processing of your personal data is based on your consent or on a
                      contract and such processing is carried out by automated means.
                    </li>
                    <li>
                      A right to object to processing: you have the right to object at
                      any time, on grounds relating to your particular situation, to
                      processing of personal data concerning you when such processing is
                      based on the legitimate interest of Next Grade. Next Grade may,
                      however, invoke compelling legitimate grounds for continued
                      processing. When your personal data are processed for direct
                      marketing purposes, you have the right to object at any time to the
                      processing of such data. You can change your marketing preferences
                      at any time as described in the Your Rights and Choices section
                      above.
                    </li>
                    <li>
                      A right to lodge a complaint with the supervisory authority: if
                      your request or concern is not satisfactorily resolved by us, you
                      may approach
                      <a
                        href="https://edpb.europa.eu/about-edpb/board/members_en"
                        target="_blank"
                        >your local data protection authority</a
                      >. The Information Commissioner is the supervisory authority in the
                      UK and can provide further information about your rights and our
                      obligations in relation to your personal data, as well as deal with
                      any complaints that you have about our processing of your personal
                      data. The CNIL is the supervisory authority in France. The Spanish
                      Data Protection Agency is the supervisory authority in Spain.
                      <a
                        href="https://www.datenschutz-wiki.de/Aufsichtsbeh%c3%b6rden_und_Landesdatenschutzbeauftragte"
                        target="_blank"
                        >This list</a
                      >
                      identifies the supervisory authorities for the 16 states in Germany.
                    </li>
                  </ul>
                </dd>

                <dd>
                  You can exercise your rights by contacting us
                  <a href="privacy-policy.html.html">here.</a>
                </dd>

                <dt class="heading-4">EU-US Privacy Shield</dt>
                <dd>
                  <em
                    >Next Grade's commitment to comply with the Privacy Shield
                    Principles</em
                  >
                </dd>
                <dd>
                  Next Grade is committed to compliance with the EU-US Privacy Shield (as
                  set forth by the U.S. Department of Commerce) regarding the collection,
                  use, and retention of Personal Information from European Union member
                  countries. Next Grade has self-certified that it adheres to each of the
                  Privacy Shield Principles:

                  <ul>
                    <li>Notice</li>
                    <li>Choice</li>
                    <li>Accountability for Onward Transfer</li>
                    <li>Security</li>
                    <li>Data Integrity and Purpose Limitation</li>
                    <li>Access</li>
                    <li>Recourse, Enforcement, and Liability</li>
                  </ul>
                </dd>
                <dd>
                  If there is any conflict between the terms in this Privacy Policy and
                  the Privacy Shield Principles, the Privacy Shield Principles shall
                  govern. To learn more about the Privacy Shield program, and to view our
                  certification, please visit
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000GnyNAAS&status=Active"
                    target="_blank"
                    >here</a
                  >.
                </dd>

                <dd>
                  <strong>Accountability for onward transfers. </strong>The Privacy Shield
                  Principles describe Next Grade’s responsibility for Personal Information
                  that it receives from EU residents under the Privacy Shield and
                  subsequently transfers to a third party processor. In particular, if
                  Third Party Agents that process Personal Information on Next Grade’s
                  behalf do so in a manner inconsistent with the Privacy Shield
                  Principles, Next Grade will remain responsible and liable unless it can
                  prove that it is not responsible for the event giving rise to the
                  damage.
                </dd>
                <dd>
                  <strong>Right of access. </strong>You have a legal right to request a
                  copy of the Personal Information that we have about you. You also have a
                  right to correct, amend or delete such Personal Information where it is
                  inaccurate or has been processed in violation of the Privacy Shield
                  Principles.
                </dd>
                <dd>
                  <strong>Resolution of disputes and complaint mechanism. </strong>In
                  compliance with the Privacy Shield Principles, Next Grade commits to
                  resolve complaints about your privacy and our collection or use of your
                  Personal Information. If European Union citizens have any queries
                  related to the processing of your Personal Information under the Privacy
                  Shield framework, we encourage you to contact us directly in the first
                  instance at:
                  <a href="mailto:privacy@nxgrade.com">privacy@Next Grade.com</a>.
                </dd>
                <dd>
                  <strong>Binding arbitration. </strong>As further explained in the
                  Privacy Shield Principles, a binding arbitration option will also be
                  made available to you in order to address residual complaints regarding
                  Next Grade’s Privacy Shield certification not resolved by any other
                  means.
                </dd>
                <dd>
                  <strong>Enforcement. </strong>Next Grade is subject to the investigatory
                  and enforcement powers of the U.S. Federal Trade Commission (FTC).
                </dd>
                <dd>
                  <strong
                    >Disclosure of EU personal data to public authorities and law
                    enforcement agencies. </strong
                  >Next Grade may be required to share your Personal Information in
                  response to lawful requests by public authorities.
                </dd>
                <dd>
                  <strong>Interest-Based Advertising in the EEA or Switzerland</strong>
                </dd>
                <dd>
                  For more information about interest-based ads, or to opt out of having
                  your web-browsing information used for behavioral advertising purposes,
                  please visit
                  <a href="http://youronlinechoices.eu/" target="_blank"
                    >youronlinechoices.eu/</a
                  >.
                </dd>
                <dd><strong>Residents of France</strong></dd>
                <dd>
                  You have the right to give Next Grade instructions concerning the use of
                  your Personal Information after your death. You can exercise this right
                  by contacting us <a href="help.html">here</a>.
                </dd>
                <dd><strong></strong></dd>
                <dd><strong></strong></dd>

                <dt class="heading-4">
                  Next Grade does not knowingly collect Personal Information from children
                  under 15 years of age in France. The Next Grade Platform is not directed
                  to children under the age of 15 and children under 15 are not permitted
                  to use the Next Grade Platform. If we become aware that we have
                  unknowingly collected Personal Information from a child under the age of
                  15, we will delete or disable such information. If you are a parent or
                  guardian of a child under the age of 15 and you believe that your child
                  has provided us with Personal Information, please let us know by
                  contacting us <a href="help.html">here</a>.
                </dt>
                <dd></dd>

                <dd><strong>Residents of Spain</strong></dd>
                <dd>
                  Next Grade does not knowingly collect Personal Information from children
                  under 14 years of age in Spain. The Next Grade Platform is not directed
                  to children under the age of 14 and children under 14 are not permitted
                  to use the Next Grade Platform. If we become aware that we have
                  unknowingly collected Personal Information from a child under the age of
                  14, we will delete or disable such information. If you are a parent or
                  guardian of a child under the age of 14 and you believe that your child
                  has provided us with Personal Information, please let us know by
                  contacting us <a href="help.html">here</a>.
                </dd>
                <dd><strong>D. Residents of Canada.</strong></dd>
                <dd>
                  <strong>Transfer Of Data. </strong>We and our affiliates and Third Party
                  Agents primarily store data about you, including Personal Information,
                  on servers located and operated within the United States. If you reside
                  or are located outside of the U.S., we may send and store your Personal
                  Information (also commonly referred to as personal data) to the U.S. in
                  order to provide and operate the Next Grade Platform. By accepting the
                  terms of this Privacy Policy, you acknowledge the transfer to and
                  processing of your Personal Information on servers located in the U.S.
                  and other countries.
                </dd>
                <dd>
                  <strong>Custom Audience. </strong>We may use services provided by
                  third-party platforms (such as social networking or other websites) to
                  serve targeted advertisements on such platforms to you, and we may
                  provide a hashed version of your email address or other information to
                  the platform provider for such purposes. To opt-out of the use of your
                  information for such purposes, please launch the opt-out tool at
                  <a href="https://youradchoices.ca/choices" target="_blank"
                    >https://youradchoices.ca/choices</a
                  >.
                </dd>

                <!--           comment       -->
                <dd>
                  <strong>Interest-Based Advertising in Canada. </strong>For more
                  information about interest-based ads, or to opt out of having your
                  web-browsing information used for behavioral advertising purposes,
                  please visit https://youradchoices.ca/choices.
                </dd>
                <dd>
                  To learn more about interest-based advertising in mobile apps and to opt
                  out of this type of advertising by third-party advertising companies
                  that participate in the DAAC’s AppChoices tool, please download the
                  version of AppChoices for your device at
                  https://youradchoices.ca/appchoices/.
                </dd>
                <dd><em>Social Media and Digital Advertising cookies and widgets</em></dd>
               <div :style="($vuetify.breakpoint.name=='sm' || $vuetify.breakpoint.name=='xs') ? 'width:auto; overflow-x:scroll' : 'width:auto;'">
                <table style="width:100%">
                  <thead >
                    <tr>
                      <th scope="col">Type of Cookie</th>
                      <th scope="col">Purpose</th>
                      <th scope="col">Who Serves (for example)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Authentication Cookies</td>
                      <td>
                        These cookies (including local storage and similar technologies)
                        tell us when you’re logged in, so we can customize your experience
                        and connect your account information and settings.
                      </td>
                      <td>@Next Grade</td>
                    </tr>
                    <tr>
                      <td>Localization</td>
                      <td>
                        These cookies help provide a localized experience by showing you
                        your local metro area.
                      </td>
                      <td>@Next Grade</td>
                    </tr>
                    <tr>
                      <td>Site features and services</td>
                      <td>
                        These provide functionality that helps us deliver products and the
                        Next Grade Platform. For example, cookies help you log in by
                        pre-filling fields or help ensure older versions of web browsers
                        can still view our Site(s). We may also use cookies and similar
                        technologies to help us provide you with social plugins and other
                        customized content and experiences, including customized fonts.
                      </td>
                      <td>
                        • Next Grade • Facebook • Linkedin • Amazon- Hosting • Zopim •
                        Polyfill • MyFonts Counter
                      </td>
                    </tr>
                    <tr>
                      <td>Analytics and research</td>
                      <td>
                        These are used to understand, improve, and research products and
                        services, including when you access the Next Grade Platform and
                        related websites and apps from a computer or mobile device. For
                        example, we may use cookies to understand how you are using site
                        features, to report on any errors in how the Site is functioning,
                        to report to our vendors when content licensed from them is
                        assessed, and to segment audiences for feature testing. We and our
                        partners may use these technologies and the information we receive
                        to improve and understand how you use websites, apps, products,
                        services and ads.
                      </td>
                      <td>
                        • Google Analytics • HeapAnalytics • MixPanel • BugSnag • Google
                        Tag Manager
                      </td>
                    </tr>
                    <tr>
                      <td>Interest-Based Advertising</td>
                      <td>
                        Things like cookies and pixels are used to deliver relevant ads,
                        track ad campaign performance and efficiency, and to understand
                        your interests from your online activity on the Site, mobile
                        applications and other websites and apps. For example, we and our
                        ad partners may rely on information gleaned through these cookies
                        to serve you ads that may be interesting to you on other websites
                        and in doing that your information (which will not contain your
                        name, email address or other "real-world" identifiers) will be
                        shared with other platforms in the digital advertising ecosystem
                        all involved in assisting the delivery, purchase, reporting and
                        analysis of digital advertising. Similarly, our advertisers may
                        use a cookie, attribution service or another similar technology to
                        determine whether we’ve served an ad and how it performed, or
                        provide us with information about how you interact with them.
                        Please note that even if you opt-out of interest-based advertising
                        by a third party, these tracking technologies may still collect
                        data for other purposes, including analytics, and you may still
                        see ads from us, but the ads will not be targeted based on
                        behavioral information about you and may therefore be less
                        relevant to you and your interests. You can instruct your browser,
                        by changing its options, to stop accepting cookies or to prompt
                        you before accepting a cookie from the websites you visit. To
                        successfully opt-out, you must have cookies enabled in your web
                        browser. Please see your browser’s instructions for information on
                        cookies and how to enable them. Your opt-out only applies to the
                        web browser you use so you must opt-out of each web browser on
                        each device that you use. Once you opt out, if you delete your
                        browser’s saved cookies, you may need to opt-out again. For more
                        information about targeting and advertising cookies and how you
                        can opt out, you can visit the
                        <a href="https://optout.networkadvertising.org/?c=1">
                          Network Advertising Initiative</a
                        >
                        opt-out page, or the
                        <a href="https://optout.aboutads.info/?c=2&lang=EN">
                          Digital Advertising Alliance’s</a
                        >
                        opt-out pages in the United States,
                        <a href="https://youradchoices.ca/choices/">
                          https://youradchoices.ca/choices/</a
                        >
                        in Canada, or
                        <a href="http://youronlinechoices.eu/"
                          >http://youronlinechoices.eu</a
                        >
                        in the EU.
                      </td>
                      <td>
                        • Next Grade • Google • Doubleclick • Iterable • Horizon • Bing
                        Ads
                      </td>
                    </tr>
                  </tbody>
                </table>
               </div>
              </dl>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <!-- Page Footer-->
     
    </div>
    <div class="snackbars" id="form-output-global"></div>
  </div>
</template>

<script>

var _gaq = _gaq || [];
_gaq.push(["_setAccount", "UA-7078796-5"]);
_gaq.push(["_trackPageview"]);
(function () {
  var ga = document.createElement("script");
  ga.type = "text/javascript";
  ga.async = true;
  ga.src =
    ("https:" == document.location.protocol ? "https://ssl" : "https://www") +
    ".google-analytics.com/ga.js";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(ga, s);
})();

export default {
  name: "privacyPolicy",
};
</script>

<style>
.ie-panel {
  display: none;
  background: #212121;
  padding: 10px 0;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
  clear: both;
  text-align: center;
  position: relative;
  z-index: 1;
}

html.ie-10 .ie-panel,
html.lt-ie-10 .ie-panel {
  display: block;
}
</style>
