import * as firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCPEoX8IcmTGWiAJwsAY5QL4E51CXMxIic",
    authDomain: "nextgrade-8e531.firebaseapp.com",
    projectId: "nextgrade-8e531",
    storageBucket: "nextgrade-8e531.appspot.com",
    messagingSenderId: "426435989877",
    appId: "1:426435989877:web:a3a8674c68e8e41a18c33c",
    measurementId: "G-24JGXGL0X0"
  };
  
  // Initialize Firebase
  // console.log('yo',firebaseConfig)

 firebase.initializeApp(firebaseConfig);