var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"background":"#f5f5f5"}},[_c('div',{staticClass:"main"},[(_vm.search)?_c('div',[_c('SearchComp',{on:{"handle-search-comp":_vm.showSearch,"handle-search-comp-off":_vm.showSearchOff}})],1):_vm._e(),(!_vm.search)?_c('div',[_c('NavbarComp',{on:{"handle-show":_vm.showCourses,"handle-search":_vm.showSearch,"handle-show-off":_vm.showCourseOff}})],1):_vm._e(),(!_vm.search)?_c('div',{staticStyle:{"min-height":"400px"}},[_c('BodyComp',{attrs:{"courseExpend":_vm.courseShow},on:{"handle-show-off":_vm.showCourseOff}})],1):_vm._e(),_c('div',{staticClass:"desktop"},[(
      this.$route.name == 'logincomp' 
      ||this.$route.name == 'loginStudentcomp' 
      ||this.$route.name == 'loginTeachercomp'
      ||this.$route.name == 'TeacherForgotPassword' 
      ||this.$route.name == 'TeacherChangePasswordComp' 
      || this.$route.name == 'signUpcomp' 
      || this.$route.name == 'signUpStudentComp' 
      || this.$route.name == 'signUpTeacherComp' 
      || this.$route.name =='ForgotPassword'
      || this.$route.name =='ConfirmPinComp'
      || this.$route.name =='ChangePasswordComp'
      || this.$route.name =='StudentMessage'
      || this.$route.name =='teacherMessage'
      )?_c('div'):_c('div',[_c('FooterComp',{on:{"handle-show-off":_vm.showCourseOff,"handle-search-comp-off":_vm.showSearchOff}})],1)]),_c('div',{staticClass:"tablate"},[_c('FooterComp',{on:{"handle-show-off":_vm.showCourseOff,"handle-search-comp-off":_vm.showSearchOff}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }